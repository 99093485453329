import './BrowseTalent.css';
import React from 'react';
import { useState, useEffect } from 'react';
import axios from 'axios';
import select from 'react-select';
import profile from './assets/profile_img.png';
import { event } from 'jquery';
import styled from 'styled-components';
import { style } from '@mui/system';
import star from './assets/star.png';
import { useDispatch, useSelector } from "react-redux";
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { login } from './action/auth';
import logo from './assets/programmer.png';
import moment from 'moment';

import AWS from 'aws-sdk';
import { setData } from '@telerik/kendo-intl';
import { useAuthToken } from "../TokenContext";

const config = {
  bucketName: process.env.REACT_APP_BUCKETNAME,
  region: process.env.REACT_APP_REGION,
  accessKeyId: process.env.REACT_APP_ACCESSKEYID,
  secretAccessKey: process.env.REACT_APP_SECRETACCESSKEY
}

const S3Config = {
  region: process.env.REACT_APP_REGION,
  accessKeyId: process.env.REACT_APP_ACCESSKEYID,
  secretAccessKey: process.env.REACT_APP_SECRETACCESSKEY
}


const Browsetalentspartner = props => {
  const { isLoggedIn, user } = useSelector(state => state.auth);
  const [userData, setUserData] = useState([]);
  const [userSearchData, setUserSearchData] = useState([]);
  const [name, setName] = useState('');
  const [profession, setProfession] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [job, setJob] = useState('');
  const [data, SetData] = useState([]);
  const [Team, SetTeam] = useState([]);
  const [Candidatetoadd, SetCandidatetoadd] = useState('');
  const [AuthImage, setAuthImage] = useState(""); 
  const myBucket = new AWS.S3(S3Config);

  const token = useAuthToken();

  const [currentPage, setcurrentPage] = useState(1);
  const [itemsPerPage, setitemsPerPage] = useState(4);
  const [pageNumberLimit, setpageNumberLimit] = useState(5);
  const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(5);
  const [minPageNumberLimit, setminPageNumberLimit] = useState(0);
  const [value,setValue]=useState('default');
  const[mis,setMis]=useState('');
const [bdg,setBdg]=useState('');
const[mr,setMr]=useState('');
const [pri,setPri]=useState('');
const[sec,setSec]=useState('');
const[tz,setTz]=useState('');
const[Title,setTitle]=useState('');
const[avail,setAvail]=useState('');
const [pref,setPref]=useState('');
const[rep,setRep]=useState([]);
const[Candidatedata,setCandidatedata]=useState([]);
const[filderdata,setFilterdata]=useState([]);
const[searchfilter,setSearchfilter]=useState('');
const[searchApiData,setSearchApiData]=useState([]);
const [len,setLen]=useState(0);
// const dataPurl='https://s3.us-east-1.amazonaws.com/prismpoddata'

// const Backdata = () => {
//   setRep(filderdata);
//   setCandidatedata(filderdata);
// }
  const handleClick = (event) => {
    setcurrentPage(Number(event.target.id));
  }
  const available=(val) =>{
    setAvail(val.target.value);
  }

  const jobtitle=(val) =>{
    setTitle(val.target.value);
  }
  const resetAvailableField =() => {
    setAvail('');
  }
  const resetTitleField =() => {
    setAvail('');
  }
  const preferfield=(val) =>{
    setPref(val.target.value);
  }
  const resetPreferField =() => {
    setPref('');
  }
  const handleUserInput = (val) => {
    setMr(val.target.value);
  }
  const resetInputField = () => {
    setMr('');
  }
  const handleJob = (val) => {
    setMis(val.target.value);
  }
  const resetJobField = () => {
    setMis('');
  }
const budgetfield =(val) =>{
  setBdg(val.target.value);
}
const resetBudgetField = () => {
  setBdg('');
}
const primaryfield =(val) =>{
  // alert(val.target.value);
  setPri(val.target.value);
}
const resetPrimaryField = () => {
  setPri('');
}
const secondaryfield =(val) =>{
  setSec(val.target.value);
}
const resetSecondaryField = () => {
  setSec('');
}
const timezonefield =(val) =>{
  setTz(val.target.value);
}
const resetTimezoneField = () => {
  setTz('');
}

const handleChange = (e) => {
  setValue(e.target.value);
};
  const pages = [];
  for (let i = 1; i <= Math.ceil(rep.length / itemsPerPage); i++) {
    pages.push(i);
  }
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = rep.slice(indexOfFirstItem, indexOfLastItem);
  const renderPageNumbers = pages.map(number => {
    if (number < maxPageNumberLimit + 1 && number > minPageNumberLimit) {
      return (
        <li key={number} id={number} onClick={handleClick} className={currentPage == number ? 'active' : null}>{number}</li>
      );
    } else {
      return null;
    }
  });


  
  const dispatch = useDispatch();

  const [Loading, setLoading] = useState(false);

  var scrollTop = () => {
    window.scrollTo(0, 0);
};


  useEffect( async () => {
    
    try {
      const data = {
        partner_id : user.data[0].id 
      }
      const headers = {
        'Authorization' : token, // Replace with your actual token
        'Content-Type': 'application/json', // You can add more headers as needed
      };
     

      // https://a8da94tu54.execute-api.us-east-1.amazonaws.com/dev/ppod-partnet-get-teamambers
      // https://sfhr6xe082.execute-api.us-east-1.amazonaws.com/dev/getteammembers
  
      const response = await axios.post(process.env.REACT_APP_PARTNER_PPODPARTNERGETTEAMMEMBERS, data,{headers:headers});
  
    
      SetTeam(response.data.data);     
    } catch (error) {
      console.log(error);
    }

 

  }, [token])


  
function filter(c_id) {

  return Team.map((data) => {
      
      return data.candidateID == c_id;
      
      });

};



  const addCandidate = async (c_id) => {

    try {


      scrollTop();

      setLoading(true)


      const data = {
        provider_id : user.data[0].id, 
        candidateID : c_id
      }
      const headers = {
        'Authorization' : token, // Replace with your actual token
        'Content-Type': 'application/json', // You can add more headers as needed
      };
  
      
      // https://a8da94tu54.execute-api.us-east-1.amazonaws.com/dev/ppod-partner-addteammember-into-pod
      const response = await axios.post(process.env.REACT_APP_PARTNER_PPODPARTNERADDTEAMMEMBERINTOPOD, data,{headers:headers});
      
      
    
      if(response.data.data == 'Create the pod to add the candidate!') {
        alert("Create the pod to add the candidate!");
        setLoading(false)
      }else{

        dispatch(login(user.data[0].email, "employer"));
      
        setTimeout(() => {
          alert("Candidate Added to your team!");
          setLoading(false)
          // window.location.reload();
        }, 5000);

      }

    
      
    } catch (error) {
      console.log(error);
      alert(error.data);
      setLoading(false)

    }

  }

  function generatePreSignedPutUrl11(profilePicture) {
   
    const URL_EXPIRATION_TIME = 900;
  
                  const params = {
                    Bucket: process.env.REACT_APP_BUCKETNAME,
                    Key: profilePicture,
                    Expires: URL_EXPIRATION_TIME,
                  }
                 
               myBucket.getSignedUrl('getObject', params, (err, url) => {
  
                setAuthImage(url);
  
              });
  }
  

 

  const renderData = (rep) => {
    return (
      <Pega>
      <Pagination1 style={{width:'100%'}}>
        <div className='paginationcontent' >
          <Num>
            <ol className='pageNumbers' >
            <Num2>
              <li>
                <button onClick={handlePrevbtn}
                  disabled={currentPage == pages[0] ? true : false} >
                  Prev
                </button>
              </li>
              <Numbers>{renderPageNumbers} </Numbers>
              <li>
                <button onClick={handleNextbtn}
                  disabled={currentPage == pages[pages.length - 1] ? true : false}
                > Next</button>
              </li>
              </Num2>
            </ol>
<Num1>
            <p className='length'> {Candidatedata.length} Candidates</p>   <br></br>
            </Num1>
          </Num>
          
<ol className='ol'>
          {
                    rep && rep.length > 0 ?
                      rep.map((dev,index )=>{
                        return <li className='smg' key={index}><div  className='name'>   <img style={{ width: '50px', height: '50px', borderRadius: "50px" }} src={'https://globldata.s3.amazonaws.com/'+ dev.profilePicture}></img> <p className='firstname'><a href={`/candidatebyid/${dev.c_id}`}>{dev.firstName}  </a></p> </div> <div className='name2'><img  style={{width:'13px' ,height:'13px'}} src={star}></img>4.5<p className='place'>{dev.country}</p> </div> <div><p  className='name1'>{dev.work_position}</p></div><div className='skill'> <p className='about'>{dev.about}</p></div><div className='time'><p className='role'>{dev.primaryskill}</p> <p className='role'>{dev.secondaryskill}</p> </div>
                        <Col><p className='time1'>{dev.timezone}</p><p className='role'>{dev.availability}</p> <p className='role'>{dev.workhours}</p> {dev.currentStatus == "Yes" ? (<p className='role' style={{ color: "green" }}>Currently Available</p>) : (<><p className='role' style={{ color: "red" }}>Currently Unavailable</p> <p className='role' style={{ color: "red" }}>Available Date : { moment(dev.availabledate).format("YYYY-MM-DD")}</p></>)}<p className='budget1'>
                          
                          { filter(dev.c_id).includes(true) ? (
 <><p className='budget1'>Already added to your pod.</p></>   ) : ( <> 
                    
  <button className='sub' onClick={() => {
// if(user.subscriptiondata.length > 0){ 
// if(user.subscriptiondata[0].Status == "active" && user.subscriptiondata[0].CandidateBalanace > 0 ){

  // if(user.subscriptiondata[0].region == dev.country){
   
    var then = new Date(dev.availabledate);
    var now = new Date();

    const msBetweenDates = Math.abs(then.getTime() - now.getTime());

    const daysBetweenDates = msBetweenDates / (24 * 60 * 60 * 1000);
   
    if(dev.currentStatus == "No"){
      if(then > now) {

       if(daysBetweenDates < 30){
                addCandidate(dev.c_id);   
      }else{
        alert("Sorry, currenlty candidate is not available to add to team!"); 
      }  
    } else{
      alert("Sorry, candidate is not available to add to team!"); 

      }  
    }else{
      addCandidate(dev.c_id);   
    }
  // }else{
  //   alert("The candidate you are adding is not in your region!"); 
  //   console.log("The candidate you are adding is not in your region!",user.subscriptiondata[0].country); 
  // }
// }else{
//   alert("You Can't add More Candidates! Please Upgrade."); 
//   window.location.href = "/subscriptions" 
// }

// }else{
//   alert("You are not subscribed yet!");
//   window.location.href = "/subscriptions" 

// }

}}>Add to Team </button></>    )}

</p></Col></li>
                      }
                      )
                      : 'No data'
                  }
</ol>
        </div>
        </Pagination1>
        </Pega>
    )
  }
  useEffect(() => {

   
    per();
    setLen(rep.length);
    const data = [
      { name: "manoj", age: "29", profession: 'se' },
      { name: "virat", age: "39", profession: 'bat' },
      { name: "rohit", age: "49", profession: 'bat' },
      { name: "shami", age: "59", profession: 'boll' }
    ];
    setUserData(data);
    setUserSearchData(data);
    
  }, [])
  const handleNextbtn = () => {
    setcurrentPage(currentPage + 1);
    if (currentPage + 1 > maxPageNumberLimit) {
      setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit + pageNumberLimit);
    }
  };
  const handlePrevbtn = () => {
    setcurrentPage(currentPage - 1);
    if ((currentPage - 1) % pageNumberLimit == 0) {
      setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit - pageNumberLimit);
    }
  };
  const per=() =>{
    const kk= (mis!= 'default')? mis: '';
    const tk=(mr!= 'default')? mr: '';
    const bd=(bdg!= 'default')? bdg: '';
    const ps= (pri != 'default')? pri: '';
    const ss=(sec  != 'default')? sec: '';
    const tmz=(tz  != 'default')? tz: '';
    const avb=(avail != 'default')? avail: '';
    const Prefered=(pref != 'default')? pref: '';
 
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json','Authorization' : token,  },
      body: JSON.stringify({
        "project_location": kk,
        "project_name": Title,
        "budget": bd,
        "primaryskill": ps,
        "secondaryskill": ss,
        "timezone": tmz,
        "availability": avb,
        "workhours": Prefered,
        "about": mr,
        "partner_id":user.data[0].id
    })
    
  };

//   {
//     "project_location" : kk,
//     "project_name": Title,
//     // "skills" : "",
//     "budget" : bd,
//     "primaryskill":ps,
//     "secondaryskill":ss,
//     "timezone":tmz,
//     "availability":avb,
//    "workhours":Prefered,
//    "about": mr
// }
  
  // https://q35udmt5od.execute-api.us-east-1.amazonaws.com/dev/project_search_by_location
  // https://a8da94tu54.execute-api.us-east-1.amazonaws.com/dev/ppod-partner-talent-list
  fetch(process.env.REACT_APP_PARTNER_PPODPARTNERTALENTLIST,requestOptions)
  .then((response) => response.json())


  .then((json) => { setRep(json.data);
    //  generatePreSignedPutUrl11(json.data.data[0].profilePicture);
   
    
     setCandidatedata(json.data);
    setFilterdata(json.data);
    setSearchApiData(json.data);
   

    });

}
function getData (val){
setMis(val.target.value);

}
function hey (val){
  setMr(val.target.value);

  }
  function budget(val){
    setBdg(val.target.value);
  
  }
  function priskill(val){
    setPri(val.target.value);
 
  }
  function secskill(val){
    setSec(val.target.value);
 
  }
  function tmzone(val){
    setTz(val.target.value);

  }
  function Availability(val){
    setAvail(val.target.value);

  }
  function preferworkhours(val){
    setPref(val.target.value);
   
  }
// const handleSearch = () => {
//   const newData = userData
//     .filter(x => x.name == (name == '' ? x.name : name))
//     .filter(y => y.profession == (profession == '' ? y.profession : profession))
//   setUserSearchData(newData);
// }
const countryData = [{ 'value': 1, label: 'India' }, { 'value': 2, label: 'USA' }, { 'value': 3, label: 'Canada' }];
const [selectedOption, setSelectedOption] = useState(null);
const handlechange = e => {
  setSelectedOption(e);
}
const handlePageClick = (pdata) => {
 
}
const [selected, setSelected] = useState([]);
const handleSelectChange = (values) => {
  setSelected(values);
};
const [selectedone, setSelectedone] = useState([]);
const handleSelectChangeone = (values) => {
  setSelectedone(values);
};
const countryoptions = [
  { value: "0", label: "India", abbreviation: "Ind" },
  { value: "14", label: "USA", abbreviation: "US" },
  { value: "15", label: "Canada", abbreviation: "Can" }
];
const searchskillsoptions = [
  { value: "0", label: "Software Developer" },
  { value: "14", label: "Software Engineer" },
  { value: "15", label: "Computer Software" }
];

const [searchText,setSearchText] =useState('');
const[searchdata,setSearchdata]=useState(rep);

// const handlechangesearch = value =>{
//   setSearchText(value);
//   filterData(value);
// }





const handlefilter = (e)  =>   { 
  if (e.target.value == ''){ 
     setRep(searchApiData)

}else { 
  
 const filterResult = searchApiData.filter(item => item.firstName.toLowerCase().includes(e.target.value.toLowerCase())|| item.primaryskill.toLowerCase().includes(e.target.value.toLowerCase())|| item.secondaryskill.toLowerCase().includes(e.target.value.toLowerCase()))
if (filterResult.length > 0){ 
 setRep (filterResult)
} else { 

setRep([{'name': 'No Data'} ])
}
}

setSearchfilter(e.target.value)
}

const searchFilterfunction =(event)  =>   { 

  if (event)  {
    const newData=rep.filter((item)=>   {  
      const itemData=item.rep ? item.rep.toUpperCase() : '' .toUpperCase();
      const textData =event.toUpperCase();
      return itemData.indexOf(textData) > -1;
    });
   
    setRep(newData);
    // setCandidatedata(newData);
 

   }else {
  
    setRep(Candidatedata);
 
    }
}
return (
  <Browsecontainer >
    <div  >
      <Heading>
         Browse Talent
          </Heading>
          <br></br>
        <div  >  <Para> Find the best freelancers or talent pods to get your projects delivered at a faster pace!
        </Para>
        </div>
        <br></br>
       {Loading ? (<><p className='budget1' style={{marginLeft: "650px", fontWeight: "bold"}}>Adding Candidate Wait...</p></>): (<></>)} 
          <br></br>


      <Main >
        {/* <div className='center'> */}
          <Table >
            <div className='singlecontainer' >
              <Singlecontainer>
                    <td className='mis' style={{width:218}}>
                    <SearchTag>
                      <SearchOutlinedIcon style={{marginTop:'15px',marginLeft: '20px',margin:'auto',color:'#7E7E7E'}} />
                      <input     className='jobsearch' type='text' placeholder='Keyword' value={searchfilter} onChange={(e)=> handlefilter(e)  }   />

                    
                      </SearchTag>
                    </td>
                    {/* <td className='mis1' >
                    <Selecttag>
                      <select className='select'  value={mis} onChange={((val)=>setMis(val.target.value),handleJob)} >
                        <option value='default'>Location</option>
                        <option value='usa'>USA</option>
                        <option value='india'>India</option>
                        <option value='canada'>Canada</option>
                      </select>
                      </Selecttag>
                    </td> */}
                <td >  <Clear   onClick={() => { resetInputField(); resetJobField(); resetBudgetField(); resetPrimaryField(); resetSecondaryField(); resetTimezoneField();  resetPreferField(); resetAvailableField(); resetTitleField();} }
                  >Clear</Clear>  </td>
                  <Search>
                    <td  ><button style={{ marginTop:'10px',width: "10rem", height: '2.6rem', color: 'white', border: '1px solid  #1DBF73', borderRadius: '5px', cursor: 'pointer', backgroundColor: '#1DBF73', boxShadow: '1px 6px 9px #00000029',fontFamily:'Poppins,Regular',fontWeight:"500"}} onClick={() => per()}>Search</button></td>
                  </Search>
              </Singlecontainer>
            </div>
            {/* <div className='main'> */}
           <Row>
                {/* <tr> */}
                <Row1>
                    <td><select className='options'  value={pri} onChange={((val)=> setPri(val.target.value),primaryfield )} >
                      <option value='default' >Primary Skill</option>
                      <option value='html'> HTML/CSS</option>
                      <option  value='JavaScript'>JavaScript/jQuery</option>
                      <option value='Adobe'>Adobe Illustrator</option>
                      <option value='React'>ReactJS</option>
                      <option value='AngularJS'>AngularJS</option>
                      <option value='Vue'>Vue</option>
                      <option value='Next'>Next</option>
                      <option value='Typescript'> Typescript</option>
                      <option  value='Gatsby'>Gatsby</option>

<option  value='ThreeJs'>Three.js</option>
                      <option value='NodeJs'>Node.js</option>
                      <option  value='Java'>Java</option>
                      </select></td>
                    <td><select className='options'  value={sec} onChange={((val)=>setSec(val.target.value), secondaryfield )} >
                      <option value='default' >Secondary Skill </option>
                      <option  value='Docker' >     Docker</option>
      <option value='PHP'  >PHP</option>
      <option   value='SQL'   >SQL</option>
      <option  value='Git'  >Git</option>
      <option value='Perl'  >Perl</option>
      <option  value='JavaScript'   >JavaScript</option>
      <option  value='Kotlin'   >Kotlin</option>
      <option value='Azure'  >Azure</option>
      <option   value='AWS'   >AWS</option>
      <option   value='Java'   >Java</option>
      <option   value='c++'   >c++</option>
                      </select></td>
                      </Row1>
                      <Row2>
                        <td >
<select className='options'   value={avail} onChange={((val)=>setAvail(val.target.value),available)}>
  <option  value='default'>Availability</option>
  <option value='Part time'>Part Time</option>
  <option  value='Full time' >Full Time</option>
  </select>
</td>
<td><select className='options'   value={tz} onChange={((val)=> setTz(val.target.value) , timezonefield )}>
                      <option value='default' >Time Zone</option>
                      <option value='Indian Standard time' >Indian Standard time</option>
                      <option  value='Eastern standard time' >Eastern standard time</option>
                      <option  value='Central standard time'>Central standard time</option>
                      <option  value='Mountain standard time '>Mountain standard time </option>
                      <option value='Pacific standard time'>Pacific standard time</option>
                      </select></td>
</Row2>
<Row2>
<td >
<select className='options'   value={pref} onChange={((val)=>setPref(val.target.value),preferfield)}>
  <option  value='default' >Prefered Work Hours</option>
  <option value='Morning Shift, 9am - 6pm'>Morning Shift, 9am - 6pm</option>
  <option  value='Night Shift, 10pm - 7am'> Night Shift, 10pm - 7am </option>
               
  </select>
</td>
<td >
<select className='options'   value={Title} onChange={((val)=>setTitle(val.target.value),jobtitle)}>

  <option  value='default' >Job title</option>
  <option value='Software Engineer'>Software Engineer</option>
  <option  value='Full Stack Developer'>Full Stack Developer</option>
  <option  value='Java Developer'>Java Developer</option>

  </select>
</td>

</Row2>
<tr>
<td >
<select className='options'  value={mis} onChange={((val)=>setMis(val.target.value),handleJob)} >
                        <option value='default'>Location</option>
                        <option value='usa'>USA</option>
                        <option value='india'>India</option>
                        <option value='canada'>Canada</option>
                      </select>
</td>
</tr>
<tr></tr>
                {/* </tr> */}
              <tr> <td >
                    <button  className='optionsaplly' style={{  width: "10rem", height: '2rem', color: 'white', border: 'none', borderRadius: '5px', cursor: 'pointer', backgroundColor: '#1DBF73',  boxShadow: '1px 6px 9px #00000029',fontFamily:'Poppins,Regular',fontWeight:"500"}} onClick={() => per()}>Apply Filters</button>
                    </td></tr>
                    </Row>
            {/* </div> */}
          </Table>
      </Main>
      <div>
        {renderData(currentItems) }
      </div>
    </div>
  </Browsecontainer>
)
}
const Browsecontainer = styled.div`
@media screen and (min-width:320px) and (max-width:1080px){
margin:auto;
width:100%;
display:flex;
align-items:center;
 justify-content:center;
flex-direction:column;
}
`;
const Main = styled.tr`
@media screen and (min-width:320px) and (max-width:1080px){
display:flex;
justify-content:center;
flex-direction:column;
margin:auto;
width:100%;
}
`;
const Table=styled.table`
@media screen and (min-width:320px) and (max-width:1080px){
display:flex;
 justify-content:center;
flex-direction:column;
margin:auto;
width:20rem;
height:max-content;
}
`;
const Heading = styled.div`
margin-top: 9rem;
font-size: 1.625rem;
margin-left:5rem;
@media screen and (min-width:320px) and (max-width:1080px){
display:flex;
margin:auto;
width:70%;
margin-top:10rem;
padding-bottom:2rem;
flex-direction:column;
justify-content:center;
align-items:center;
}
`;
const Para =styled.p`
margin-left:5rem;

color: #A2A2A2;
font-size:  1.125rem;
@media screen and (min-width:320px) and (max-width:1080px){
display:flex;
margin:auto;
width:20rem;

padding-bottom:2rem;
flex-direction:column;
justify-content:center;
align-items:center;
}
`;
const Singlecontainer = styled.tr`
display:flex;
flex-direction:row;
@media screen and (min-width:320px) and (max-width:1080px){
display:flex;
justify-content:center;
flex-direction:column;
align-items:center;
margin:auto;
width:20rem;

}
`;
const Row =styled.div`
background-color:white;
height:max-content;
padding-bottom:1rem;
@media screen and (min-width:320px) and (max-width:1080px){
  display:flex;
  justify-content:center;
 flex-direction:column;
 align-items:center;
width:100%;
}
`;
const Input = styled.input`
@media screen and (min-width:320px) and (max-width:1080px){
border:none;
width:100%;
height:3rem;
align-items:center;
// display:flex;
// flex-direction:row;
}
`;
const SearchTag = styled.td`
@media screen and (min-width:320px) and (max-width:1080px){
  display:flex;
width:20rem;
margin:auto;
border-bottom:1px solid #A2A2A2;
}
`;
// const Select = styled.select`
// @media screen and (min-width:320px) and (max-width:1080px){
//   width:20rem;
//  }
//  `;
const Selecttag = styled.td`
@media screen and (min-width:320px) and (max-width:1080px){
  display:flex;
  flex-direction:column;
  margin:auto;
 width:20rem;
  border-bottom:1px solid #A2A2A2;
}
`;
const Clear = styled.p`
margin-top:20px;
width:6rem;
margin-left:31rem;
cursor: pointer;
font-weight:600;
font-size: 18px;
font-family:Poppins,Regular;
@media screen and (min-width:320px) and (max-width:1080px){
  display:flex;
  align-items:center;
 flex-direction:column;
 margin-left:auto;
}
`;
const Search = styled.td`
@media screen and (min-width:320px) and (max-width:1080px){
  display:flex;
margin:auto;
width:100%;
align-items:center;
 flex-direction:column;
 margin-bottom:20px;
}
`;
const Row1 = styled.tr`
@media screen and (min-width:320px) and (max-width:1080px){
  display:flex;
// margin:auto;
 flex-direction:column;
align-items:center;
width:100%;
}
`;
const Row2 = styled.tr`
@media screen and (min-width:320px) and (max-width:1080px){
  display:flex;
// margin:auto;
 flex-direction:column;
align-items:center;
width:100%;
}
`;
const Button = styled.button`
@media screen and (min-width:320px) and (max-width:1080px){
  display:flex;
margin:auto;
 flex-direction:column;
}
`;
const Pagination1 = styled.div`
height: max-content;
margin-left: 5rem;
  width:83.5rem;
 
  background-color: white;

margin-top: 3rem;
 border:1px solid #EFEFEF;
@media screen and (min-width:320px) and (max-width:1080px){
margin:auto;
width:20rem;
flex-direction:column;
display:flex;
align-items:center;
justify-content:center;
margin-top:3rem;

}
`;
const Pega = styled.div`
// display:flex;
// justify-content:center;
// align-items:center;
// align-self:center;
@media screen and (min-width:320px) and (max-width:1080px){
width:100%;
flex-direction:column;
display:flex;
align-items:center;
justify-content:center;
}
`;
const Numbers = styled.li`
@media screen and (min-width:320px) and (max-width:1080px){
  display:flex;
  align-items:center;
justify-content:center;
width:7rem;
}
`;
const Num = styled.ol`
@media screen and (min-width:320px) and (max-width:1080px){
  display:flex;
//   align-items:center;
// justify-content:center;
flex-direction:column;
marginLeft:-3rem;
width:70%;
}
`;
const Num1 = styled.p`
@media screen and (min-width:320px) and (max-width:1080px){
  // display:flex;
  margin:auto;
width:100%;
}
`;
const Num2 = styled.li`
@media screen and (min-width:320px) and (max-width:1080px){
  display:flex;
  margin:auto;
  flex-direction:column;
  align-items:center;
justify-content:center;
width:100%;
}
`;
const Col = styled.div`
margin-top: -0.2rem;
display: flex;
flex-direction: row;
 align-items: center;
@media screen and (min-width:320px) and (max-width:1080px){
  display:flex;
  margin:auto;
  align-items:center;
  justify-content:center;
  flex-direction:column;
}
`;
export default Browsetalentspartner;
























