import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import AWS from 'aws-sdk';
import { useAuthToken } from "../TokenContext";


const config = {
  bucketName: 'globldata',
  region: process.env.REACT_APP_REGION,
  accessKeyId: process.env.REACT_APP_ACCESSKEYID,
  secretAccessKey: process.env.REACT_APP_SECRETACCESSKEY,
};

function Setting() {
  const [fetchlist, setFetchlist] = useState([]);
    const [fetchlistid, setFetchlistid] = useState('');
  const { isLoggedIn, user } = useSelector((state) => state.auth);
  const [ARTsList, setARTsList] = useState([]); // Store the list of ARTs
    
  const token = useAuthToken();


  const [formData, setFormData] = useState({
 Jira_URL:'',
    Jira_admin_login_email_address:'',
    Jira_admin_API_Token:'',
   
    Github_username:'',
    Github_API_Token:'',
  Github_Repo_name:'',
  ART_id:'',
    Organizational_Code:user.data[0].client_code,
    Jira_Kanben_Project_name:"",
    circleci_github_username:"",
    circleci_github_repo:'',
    open_ai_token:'',
 circleci_api_token:'',
    sonarqube_cloud_url:'',
    sonarqube_api_token:'',
   aws_access_key_id:'',
    aws_secret_access_key:'',
    bitbucket_username:'',
    bitbucket_app_password:'',
    bitbucket_workspace:'',
    aws_region:'',
    jira_open_story_bug_status: [], // New state for Jira open story/bug status
    jira_closed_story_bug_status: [],
    jira_story_points_calc_file: '',



  });

  const [selectedFile, setSelectedFile] = useState(null); // New state for file

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      
      ...prevFormData,
      [name]: value,
    }));
    // console.log('FormData.ART_id:', formData.ART_id);
  };
  

  const handleSubmit = async (event) => {
    const headers = {
      'Authorization' : token, // Replace with your actual token
      'Content-Type': 'application/json', // You can add more headers as needed
    };
    event.preventDefault();
    

    try {

    
        const fileName = await uploadFileToS3(selectedFile);
       
       
      const formattedData = {
        ...formData,
        jira_story_points_calc_file: fileName,
        jira_open_story_bug_status: Array.isArray(formData.jira_open_story_bug_status)
          ? formData.jira_open_story_bug_status
          : [formData.jira_open_story_bug_status],
        jira_closed_story_bug_status: Array.isArray(formData.jira_closed_story_bug_status)
          ? formData.jira_closed_story_bug_status
          : [formData.jira_closed_story_bug_status],
      };
  
      const response = await fetch(process.env.REACT_APP_CUSTOMERADMIN_AUTHORIZATIONTOKEN, {
        method: 'POST',
        headers: {
          Authorization: token, 
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          ART_id: parseInt(selectedARTId),
          ...formattedData,
        }),
      });
  
      if (response.ok) {
        alert('Details Successfully Updated');
        window.location.reload();
      } else {
        console.error('API call failed');
      }
    } catch (error) {
      console.error('An error occurred', error);
    }
  };
  
  const uploadFileToS3 = async (file) => {
    AWS.config.update({
      region: config.region,
      accessKeyId: config.accessKeyId,
      secretAccessKey: config.secretAccessKey,
    });

    const s3 = new AWS.S3();
    const params = {
      Bucket: config.bucketName,
      Key: file.name, // File name with a timestamp
      Body: file,
      ContentType: file.type,
      ACL: 'public-read', // Allow public read access if needed
    };

    try {
      const uploadResult = await s3.upload(params).promise();
      return uploadResult.Key; // Return the file name or key
    } catch (error) {
      console.error('Error uploading file to S3:', error);
      throw error;
    }
  };
 

  useEffect(() => {
    fetchauthorisationlist();
    fetchARTsList();
  }, [token]);
  
  // Update the useEffect hook to properly handle pre-filled values
  useEffect(() => {
    // Check if fetchlistid contains data and the required properties are not null
    if (
      fetchlistid &&
      fetchlistid.jira_open_story_bug_status &&
      fetchlistid.jira_open_story_bug_status[0] &&
      fetchlistid.jira_closed_story_bug_status &&
      fetchlistid.jira_closed_story_bug_status[0]
    ) {
      // Split the comma-separated strings into arrays
      const openStatuses = fetchlistid.jira_open_story_bug_status[0].split(',');
      const closedStatusArray = fetchlistid.jira_closed_story_bug_status[0].split(',');
  
  
      // Update formData with the fetched values
      setFormData((prevFormData) => ({
        ...prevFormData,
        jira_open_story_bug_status: openStatuses,
        jira_closed_story_bug_status: closedStatusArray,
      }));
    }
  }, [fetchlistid]);
  
  
  
  const fetchARTsList = async () => {
    const headers = {
      'Authorization' : token, // Replace with your actual token
      'Content-Type': 'application/json', // You can add more headers as needed
    };
    try {
      const response = await axios.post(
        process.env.REACT_APP_CUSTOMERADMIN_GENERICGIGLIST,
        { Organizational_Code: user.data[0].client_code },{headers:headers}
      );
  
      if (response.status === 200 && response.data && response.data.data) {
        setARTsList(response.data.data);
      } else {
        // Handle unexpected response or data format errors
        console.error('Unexpected API response:', response);
        // You can set an error state here if needed
      }
    } catch (error) {
      // Handle network or other errors
      console.error('Error fetching ARTs list:', error);
      // You can set an error state here if needed
    }
  };
  
 

  
  

  const fetchauthorisationlist = async () => {
    const headers = {
      'Authorization': token,
      'Content-Type': 'application/json',
    };
    try {
      const response = await axios.post(
        process.env.REACT_APP_CUSTOMERADMIN_FETCHAUTHORIZATIONTOKENS,
        { Organizational_Code: user.data[0].client_code },
        { headers: headers }
      );
  
     
      if (response.data && response.data.body && response.data.body.data.length > 0) {
        // If the API returns data, set the formData state with the fetched data
        const fetchData = response.data.body.data[0];
        setFetchlist(response.data.body.data);
        setFetchlistid(response.data.body.data[0]);
  
        setFormData({
          Jira_URL: fetchData.Jira_URL || '',
          Jira_admin_login_email_address: fetchData.Jira_admin_login_email_address || '',
          Jira_admin_API_Token: fetchData.Jira_admin_API_Token || '',
          Jira_Kanben_Project_name: fetchData.Jira_Kanben_Project_name || '',
          Github_username: fetchData.Github_username || '',
          Github_API_Token: fetchData.Github_API_Token || '',
          circleci_api_token: fetchData.circleci_api_token || '',
          sonarqube_cloud_url: fetchData.sonarqube_cloud_url || '',
          sonarqube_api_token: fetchData.sonarqube_api_token || '',
          aws_access_key_id: fetchData.aws_access_key_id || '',
          aws_secret_access_key: fetchData.aws_secret_access_key || '',
          Github_Repo_name: fetchData.Github_Repo_name || '',
          bitbucket_username: fetchData.bitbucket_username || '',
bitbucket_app_password: fetchData.bitbucket_app_password || '',
bitbucket_workspace: fetchData.bitbucket_workspace || '',
aws_region: fetchData.aws_region || '',
          Jira_Kanben_Project_name: fetchData.Jira_Kanben_Project_name || '',
          circleci_github_username: fetchData.circleci_github_username || '',
          circleci_github_repo: fetchData.circleci_github_repo || '',
          open_ai_token: fetchData.open_ai_token || '',
          ART_id: fetchData.ARTs_id || '',
          Organizational_Code: user.data[0].client_code,
          jira_story_points_calc_file:fetchData.jira_story_points_calc_file||'',

        });
      }
  
      if (response.data && response.data.length > 0) {
        const fetchedData = response.data[0];
       
        setFormData((prevFormData) => ({
          ...prevFormData,
          jira_open_story_bug_status: fetchedData.jira_open_story_bug_status[0].split(','),
          jira_closed_story_bug_status: fetchedData.jira_closed_story_bug_status[0].split(','),
        }));
      }
  
      
    } catch (error) {
      console.error('Error fetching owners:', error);
    }
  };
  
  
  
  

  const [selectedARTId, setSelectedARTId] = useState(formData.ART_id || '');
  useEffect(() => {
    setSelectedARTId(formData.ART_id || '');
  }, [formData.ART_id]);
  
  // Handle ART selection from the dropdown
  const handleARTSelect = (event) => {
    const selectedARTId = event.target.value;
    setSelectedARTId(selectedARTId); // Update the selected ART ID
    setFormData((prevFormData) => ({
      ...prevFormData,
      ART_id: selectedARTId, // Include selectedARTId in the formData
    }));
   
  };
  const handleUpdate = async (event) => {
    event.preventDefault(); // Prevent the default form submission behavior
    try {
      const headers = {
        Authorization: token,
        'Content-Type': 'application/json',
      };
      const fileName = await uploadFileToS3(selectedFile);
       
      // Ensure that selected options are arrays
      const formattedData = {
        ...formData,
        jira_story_points_calc_file: fileName,
        jira_open_story_bug_status: Array.isArray(formData.jira_open_story_bug_status)
          ? formData.jira_open_story_bug_status
          : [formData.jira_open_story_bug_status],
        jira_closed_story_bug_status: Array.isArray(formData.jira_closed_story_bug_status)
          ? formData.jira_closed_story_bug_status
          : [formData.jira_closed_story_bug_status],
      };
  
      // Make the API call to update authorization tokens
      const response = await axios.post(
        process.env.REACT_APP_CUSTOMERADMIN_UPDATEAUTHORIZATIONTOKENS,
        {
          id: fetchlistid.id,
          ...formattedData,
          ART_id: fetchlistid.ART_id,
        },
        { headers: headers }
      );
  
      if (response.status === 200) {
        alert('Updated successfully');
        window.location.reload();
      } else {
        console.error('Update API call failed');
      }
    } catch (error) {
      console.error('An error occurred while updating', error);
    }
  };
  
  
  
  

 const handleOpenStatusChange = (event, value) => {

  setFormData((prevFormData) => ({
    ...prevFormData,
    jira_open_story_bug_status: value,
  }));
};

const handleClosedStatusChange = (event, value) => {

  setFormData((prevFormData) => ({
    ...prevFormData,
    jira_closed_story_bug_status: value,
  }));
};

  
  
  
  
const [openStatusOptions, setOpenStatusOptions] = useState([
  'To Do',
  'In Progress',
  'Testing',
  'In Review',
  'Blocked',
  'Ready for QA',
]);

const [closedStatusOptions, setClosedStatusOptions] = useState([
  'Done',
  'Closed',
  'Completed',
  'Resolved',
  'Verified',
]);

  const [userAddedOptions, setUserAddedOptions] = useState([]);
  
 

  const handleAddNewOption = (option, fieldName) => {
    if (!formData[fieldName].includes(option)) {
      const newOptions = [...formData[fieldName], option];
      setFormData((prevFormData) => ({
        ...prevFormData,
        [fieldName]: newOptions,
      }));
      setUserAddedOptions([...userAddedOptions, option]);
    }
  };

  
  const handleInputChange = (event, fieldName) => {
    const newValue = Array.isArray(event.target.value) ? event.target.value : [event.target.value];
    setFormData((prevFormData) => ({
      ...prevFormData,
      [fieldName]: newValue,
    }));
  };
  
  
  
 
  const getFileUrl = (fileName) => {
    // Construct the full URL using the bucket name and encode the file name to handle spaces, parentheses, etc.
    return `https://globldata.s3.amazonaws.com/${encodeURIComponent(fileName)}`;
  };

console.log('filanem',formData.jira_story_points_calc_file)

      return (
        <Div>
          <TitleText>Settings</TitleText>
          <RecommendProject>
            {fetchlist.length === 0 ? (
              <form onSubmit={handleSubmit}>
                <CardContent>
                <Postfield>
                  <Label>Select a ART:</Label>
                  <Select  value={selectedARTId} onChange={handleARTSelect}>
  <option value="">Select an ART</option>
  {Array.isArray(ARTsList) &&
    ARTsList.map((ART) => (
      <option key={ART.ART_id} value={ART.ART_id}>
        {ART.ART_Name}
      </option>
    ))}
        </Select>
        </Postfield>
        <Postfield>
                    <Label>Jira Projectkey:</Label>
                    <Input
                      type="text"
                      name="Jira_Kanben_Project_name"
                      value={formData.Jira_Kanben_Project_name}
                      onChange={handleChange}
                    />
                  </Postfield>
                  <Postfield>
                    <Label>Jira URL:</Label>
                    <Input
                      type="url"
                      name="Jira_URL"
                      value={formData.Jira_URL}
                      onChange={handleChange}
                    />
                  </Postfield>
                  <Postfield>
                    <Label>Jira Admin Email:</Label>
                    <Input
                      type="email"
                      name="Jira_admin_login_email_address"
                      value={formData.Jira_admin_login_email_address}
                      onChange={handleChange}
                    />
                  </Postfield>
                  <Postfield>
                    <Label>Jira Admin API Token:</Label>
                    <Input
                      type="password"
                      name="Jira_admin_API_Token"
                      value={formData.Jira_admin_API_Token}
                      onChange={handleChange}
                    />
                  </Postfield>
                  <Postfield>
                <Label>Jira Open Story/Bug Status:</Label>
                <CustomAutocomplete
             
  multiple
  id="jira-open-status"
  options={openStatusOptions}
  renderInput={(params) => <CustomTextField {...params} variant="standard" />}
  onChange={(event, value) => handleOpenStatusChange(event, value)}
  renderOption={(props, option, { selected }) => (
    <li {...props}>
      <span>{option}</span>
    </li>
     )}
     
/>







              </Postfield>

              <Postfield>
                <Label>Jira Closed Story/Bug Status:</Label>
                <CustomAutocomplete
                 
  multiple
  id="jira-closed-status"
  options={closedStatusOptions}
  renderInput={(params) => <CustomTextField {...params} variant="standard" />}
  onChange={(event, value) => handleClosedStatusChange(event, value)}
  renderOption={(props, option, { selected }) => (
    <li {...props}>
      <span>{option}</span>
    </li>
  )}
/>





              </Postfield>
             
             
             
             
             
              
                  
                 
                  <Postfield>
                    <Label>Sonarqube Cloud Url:</Label>
                    <Input
                      type="url"
                      name="sonarqube_cloud_url"
                      value={formData.sonarqube_cloud_url}
                      onChange={handleChange}
                    />
                  </Postfield>
                  <Postfield>
                    <Label>Sonarqube Api Token:</Label>
                    <Input
                      type="name"
                      name="sonarqube_api_token"
                      value={formData.sonarqube_api_token}
                      onChange={handleChange}
                    />
                  </Postfield>
                  <Postfield>
                    <Label>AWS Access Key Id:</Label>
                    <Input
                      type="id"
                      name="aws_access_key_id"
                      value={formData.aws_access_key_id}
                      onChange={handleChange}
                    />
                  </Postfield>
                  <Postfield>
                    <Label>AWS Secret Access Key :</Label>
                    <Input
                      type="text"
                      name="aws_secret_access_key"
                      value={formData.aws_secret_access_key}
                      onChange={handleChange}
                    />
                  </Postfield>
               
                  <Postfield>
                    <Label>Aws Region:</Label>
                    <Input
                      type="text"
                      name="aws_region"
                      value={formData.aws_region}
                      onChange={handleChange}
                    />
                  </Postfield>
                  
                  <Postfield>
                    <Label>Github Admin Username:</Label>
                    <Input
                      type="text"
                      name="Github_username"
                      value={formData.Github_username}
                      onChange={handleChange}
                    />
                  </Postfield>
                  <Postfield>
                    <Label>Github API Token:</Label>
                    <Input
                      type="text"
                      name="Github_API_Token"
                      value={formData.Github_API_Token}
                      onChange={handleChange}
                    />
                  </Postfield>
                  <Postfield>

                    <Label>Github Repo Name:</Label>
                    <Input
                      type="text"
                      name="Github_Repo_name"
                      value={formData.Github_Repo_name}
                      onChange={handleChange}
                    />
                  </Postfield>

                  <Postfield>
                    <Label>Circleci Api Token:</Label>
                    <Input
                      type="text"
                      name="circleci_api_token"
                      value={formData.circleci_api_token}
                      onChange={handleChange}
                    />
                  </Postfield>
                  <Postfield>
                    <Label>Circleci Github Username:</Label>
                    <Input
                      type="text"
                      name="circleci_github_username"
                      value={formData.circleci_github_username}
                      onChange={handleChange}
                    />
                  </Postfield>
                  <Postfield>
                    <Label>Circleci Github Repo:</Label>
                    <Input
                      type="text"
                      name="circleci_github_repo"
                      value={formData.circleci_github_repo}
                      onChange={handleChange}
                    />
                  </Postfield>
                  <Postfield>
                    <Label>Bitbucket Username:</Label>
                    <Input
                      type="text"
                      name="bitbucket_username"
                      value={formData.bitbucket_username}
                      onChange={handleChange}
                    />
                  </Postfield>
                  <Postfield>
                    <Label>Bitbucket App Password:</Label>
                    <Input
                      type="password"
                      name="bitbucket_app_password"
                      value={formData.bitbucket_app_password}
                      onChange={handleChange}
                    />
                  </Postfield>
                  <Postfield>
                    <Label>Bitbucket Workspace:</Label>
                    <Input
                      type="text"
                      name="bitbucket_workspace"
                      value={formData.bitbucket_workspace}
                      onChange={handleChange}
                    />
                  </Postfield>
                 
                  
                 
                  <Postfield>
                    <Label>Open Ai Token:</Label>
                    <Input
                      type="password"
                      name="open_ai_token"
                      value={formData.open_ai_token}
                      onChange={handleChange}
                    />
                  </Postfield>
                  <Postfield>
                <Label>Upload Jira Story Points Calculation File:</Label>
                <input type="file" onChange={handleFileChange} />
              </Postfield>
                 
                  <Button type="submit">Submit</Button>
                </CardContent>
              </form>
            ) : (
              <form onSubmit={handleUpdate}>
                <CardContent>
                {/* <Postfield>
  <Label>Select a ART:</Label>
  <Select
    value={selectedARTId} // Use formData.ART_id to pre-fill
    onChange={(event) => setSelectedARTId(event.target.value)}
  >
    <option value="">Select a ART</option>
    {ARTsList.map((ART) => (
      <option key={ART.ART_id} value={ART.ART_id}>
        {ART.ART_Name}
      </option>
    ))}
  </Select>
</Postfield> */}


<Postfield>
                    <Label>Jira Project Key :</Label>
                    <Input
                      type="text"
                      name="Jira_Kanben_Project_name"
                      value={formData.Jira_Kanben_Project_name}
                      onChange={handleChange}
                    />
                  </Postfield>
                  <Postfield>
                    <Label>Jira URL:</Label>
                    <Input
                      type="url"
                      name="Jira_URL"
                      value={formData.Jira_URL}
                      onChange={handleChange}
                    />
                  </Postfield>
                  <Postfield>
                    <Label>Jira Admin Email:</Label>
                    <Input
                      type="email"
                      name="Jira_admin_login_email_address"
                      value={formData.Jira_admin_login_email_address}
                      onChange={handleChange}
                    />
                  </Postfield>
                  <Postfield>
                    <Label>Jira Admin API Token:</Label>
                    <Input
                      type="password"
                      name="Jira_admin_API_Token"
                      value={formData.Jira_admin_API_Token}
                      onChange={handleChange}
                    />
                  </Postfield>
                  <Postfield>
  <Label>Jira Open Story/Bug Status:</Label>
  <CustomAutocomplete
    multiple
    id="jira-open-status"
    options={openStatusOptions}
    value={formData.jira_open_story_bug_status || []}
    renderInput={(params) => <CustomTextField {...params} variant="standard" />}
    onChange={(event, value) => handleOpenStatusChange(event, value)}
    renderOption={(props, option, { selected }) => (
      <li {...props}>
        <span>{option}</span>
      </li>
    )}
  />
</Postfield>

<Postfield>
  <Label>Jira Closed Story/Bug Status:</Label>
  <Autocomplete
        multiple
        id="jira-closed-status"
        options={closedStatusOptions}
        value={formData.jira_closed_story_bug_status  ||[]} // Controlled value
        onChange={(event, value) => handleClosedStatusChange(event, value)}
        renderInput={(params) => <TextField {...params} variant="standard" />}
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <span>{option}</span>
          </li>
        )}
      />
</Postfield>






              

                  <Postfield>
                    <Label>Github API Token:</Label>
                    <Input
                      type="text"
                      name="Github_API_Token"
                      value={formData.Github_API_Token}
                      onChange={handleChange}
                    />
                  </Postfield>
                 
                  <Postfield>
                    <Label>Sonarqube Cloud Url:</Label>
                    <Input
                      type="url"
                      name="sonarqube_cloud_url"
                      value={formData.sonarqube_cloud_url}
                      onChange={handleChange}
                    />
                  </Postfield>
                  <Postfield>
                    <Label>Sonarqube Api Token:</Label>
                    <Input
                      type="name"
                      name="sonarqube_api_token"
                      value={formData.sonarqube_api_token}
                      onChange={handleChange}
                    />
                  </Postfield>
                  <Postfield>
                    <Label>AWS Access Key Id:</Label>
                    <Input
                      type="id"
                      name="aws_access_key_id"
                      value={formData.aws_access_key_id}
                      onChange={handleChange}
                    />
                  </Postfield>
                  <Postfield>
                    <Label>AWS Secret Access Key :</Label>
                    <Input
                      type="text"
                      name="aws_secret_access_key"
                      value={formData.aws_secret_access_key}
                      onChange={handleChange}
                    />
                  </Postfield>
                  <Postfield>
                    <Label>Aws Region:</Label>
                    <Input
                      type="text"
                      name="aws_region"
                      value={formData.aws_region}
                      onChange={handleChange}
                    />
                  </Postfield>
                  <Postfield>
                    <Label>Github Username:</Label>
                    <Input
                      type="text"
                      name="Github_username"
                      value={formData.Github_username}
                      onChange={handleChange}
                    />
                  </Postfield>
                  <Postfield>
                    <Label>Github Repo Name:</Label>
                    <Input
                      type="text"
                      name="Github_Repo_name"
                      value={formData.Github_Repo_name}
                      onChange={handleChange}
                    />
                  </Postfield>
                  <Postfield>
                    <Label>Circleci API Token:</Label>
                    <Input
                      type="text"
                      name="circleci_api_token"
                      value={formData.circleci_api_token}
                      onChange={handleChange}
                    />
                  </Postfield>
                  <Postfield>
                    <Label>Circleci Github Username :</Label>
                    <Input
                      type="text"
                      name="circleci_github_username"
                      value={formData.circleci_github_username}
                      onChange={handleChange}
                    />
                  </Postfield>
                  <Postfield>
                    <Label>Circleci Github Repo :</Label>
                    <Input
                      type="text"
                      name="circleci_github_repo"
                      value={formData.circleci_github_repo}
                      onChange={handleChange}
                    />
                  </Postfield>
                  <Postfield>
                    <Label>Bitbucket Username:</Label>
                    <Input
                      type="text"
                      name="bitbucket_username"
                      value={formData.bitbucket_username}
                      onChange={handleChange}
                    />
                  </Postfield>
                  <Postfield>
                    <Label>Bitbucket App Password:</Label>
                    <Input
                      type="password"
                      name="bitbucket_app_password"
                      value={formData.bitbucket_app_password}
                      onChange={handleChange}
                    />
                  </Postfield>
                  <Postfield>
                    <Label>Bitbucket Workspace:</Label>
                    <Input
                      type="text"
                      name="bitbucket_workspace"
                      value={formData.bitbucket_workspace}
                      onChange={handleChange}
                    />
                  </Postfield>
                 
                  <Postfield>
                    <Label>Open Ai Token :</Label>
                    <Input
                      type="password"
                      name="open_ai_token"
                      value={formData.open_ai_token}
                      onChange={handleChange}
                    />
                  </Postfield>
                  <Postfield>

                 
               
                <Label>Upload Jira Story Points Calculation File:</Label>

                <input type="file" onChange={handleFileChange} />
                
              </Postfield>
              <a href={getFileUrl(formData.jira_story_points_calc_file)} target="_blank" rel="noopener noreferrer">
              {formData.jira_story_points_calc_file}
            </a>
                  {/* Other input fields */}
                  <Button type='submit'>Update</Button>
                </CardContent>
              </form>
            )}
           
          </RecommendProject>
        </Div>
      );
      
}



export default Setting;
const Button=styled.button`
display:flex;
justify-content:center;
align-items:center;
margin:auto;
margin-top:2rem;
font-family:Poppins,Medium;
    background-color: #1DBF73;
    color: white;
   font-size: 15px;
     border-radius: 10px;
     height: 54px;
        width:22rem;
border:none;
    font-weight: 600;
    font-family: Poppins,Medium;
    box-shadow: 1px 3px 6px #00000029;
    @media screen and (min-width:320px) and (max-width:1080px){
        width:12rem;
        height:max-height;
        }
`;
const TitleText = styled.h4`
  font-weight: 600;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
   margin-top:2rem;
  }

`;
const Div = styled.div`
   display:flex;
   margin:auto;
  justify-content: center;
  align-items:center;
  flex-direction: column;
 
  margin-top: 6rem;



  @media screen and (min-width: 320px) and (max-width: 1080px) {
    display: flex;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    margin: auto;
    width: 25rem;
  }
`;
const RecommendProject = styled.div`

 display:flex;
 align-items:center;
jutify-content:center;
margin:auto;
flex-direction:row;
@media screen and (min-width:320px) and (max-width:1080px){
    width:100%;
    height:max-content;
    margin:auto;
    
 display:flex;


 flex-direction:column;
    
   }
`;
const CardContent = styled.div`
display:flex;

flex-direction:column;
align-items:center;
jutify-content:center;

  margin: 0.4rem;
  width: 60vw;
  padding:1rem;
  
  height:max-height;

  border-radius:15px;
  font-family:Poppins,Medium;
  background-color:#F2F4F5;

  @media screen and (min-width: 320px) and (max-width: 1080px) {
    width: 20rem;
    margin:auto;
  }
`;
const Postfield=styled.div`
font-family:Poppins,Medium;
display:flex;
justify-content:center;
align-items:center;
margin:auto;
padding:10px

@media screen and (min-width:320px) and (max-width:1080px){
  display:flex;
  flex-direction:column;
`;

const Label =styled.label`
font-family:Poppins,Medium;
font-weight: bold;
font-size:16px;
color :black;
width:15rem;
`;



const Select=styled.select`
font-family:Poppins,Medium;
width: 22rem;
height:50px;
border-radius: 10px;
border: 2px solid #B7B7B7;
padding-left:25px;
font-weight:600;
    @media screen and (min-width:320px) and (max-width:1080px){
        width:12rem;
        height:max-height;
        }
`;
const CustomAutocomplete = styled(Autocomplete)`
font-family:Poppins,Medium;
width: 22rem;

padding-left:25px;
font-weight:600;
    @media screen and (min-width:320px) and (max-width:1080px){
        width:12rem;
        height:max-height;
        }
`;
const CustomTextField = styled(TextField)`
font-family:Poppins,Medium;
width: 22rem;

padding-left:25px;
font-weight:600;
    @media screen and (min-width:320px) and (max-width:1080px){
        width:12rem;
        height:max-height;
        }
`;
const Input=styled.input`
font-family:Poppins,Medium;
width: 22rem;
height:50px;
border-radius: 10px;
border: 2px solid #B7B7B7;
padding-left:25px;
font-weight:600;
    @media screen and (min-width:320px) and (max-width:1080px){
        width:12rem;
        height:max-height;
        }
`;