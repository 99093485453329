import styled from "styled-components";
import React from "react";
import Badge from "./Badge";
import Avatar from "./assets/MaskGroup.png";
import { cardShadow, hoverEffect, themeColor } from "../utils";
import { useState } from "react";
import ShowMore from "react-show-more-button/dist/components/ShowMore";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Rsvp } from "@mui/icons-material";
import axios from "axios";
import { data, error, getJSON } from "jquery";
import { Route } from "react-router-dom";
import { NavHashLink } from 'react-router-hash-link';
import EditPostRequirementCand from './EditPostRequirementCand';
import { useParams, useHistory } from 'react-router-dom';
import PostRequirementCand from "./PostRequirementCand";
import Navbar from "../Dashboard/Navbar";
import{FiSearch} from 'react-icons/fi';
import ManagePod from "./ManagePod";
import AWS from 'aws-sdk';
import ViewPostedProReq from './ViewPostedProReq';
import Statusupdate from './Statusupdate';
import defaultimg from './assets/defaultimge.png';
import { Link } from "react-router-dom";
import ClientManagePod from './ClientManagePod';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Popup from "./Popup";
import Stakeholderinvite from "./Stakeholderinvite";
import { useAuthToken } from "../TokenContext";
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'auto',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  overflow: 'auto',
};

const config = {
  bucketName: process.env.REACT_APP_BUCKETNAME,
  region: process.env.REACT_APP_REGION,
  accessKeyId: process.env.REACT_APP_ACCESSKEYID,
  secretAccessKey: process.env.REACT_APP_SECRETACCESSKEY
}

const S3Config = {
  region: process.env.REACT_APP_REGION,
  accessKeyId: process.env.REACT_APP_ACCESSKEYID,
  secretAccessKey: process.env.REACT_APP_SECRETACCESSKEY
}




const ClientPods = () => {


  const token = useAuthToken();
  const { isLoggedIn, user } = useSelector(state => state.auth);

  const [getpoddetails, setGetpoddetails] = useState([]);
  const [getcandidates, setGetcandidates] = useState([]);
  const [getpoddetailsdata, setGetpoddetailsdata] = useState([]);
  const [podskill, setPodskill] = useState([]);
  const [id, setId] = useState('');
  const [managepod, setManagePod]=useState(false);
  const params = useParams();
  const [AuthImage, setAuthImage] = useState(""); 
  const [managePodId, setManagePodId] = useState(""); 
  const email = user.data[0].email

  const portfolioUrl = user.data[0].portfolioUrl
 
  const[invite,setinvite]=useState([{emails: ''}]);
  const [modalId, setModalId] = useState(""); 
  const[modal,setModal]=useState(false);
  const[stakemodal,setStakemodal]=useState(false);
  const[removebutton,setRemovebutton]=useState(0);
  const firstName = user.data[0].firstName
  
  const[error,setError]=useState({})
  const lastName = user.data[0].lastName
 
  const myBucket = new AWS.S3(S3Config);


  function generatePreSignedPutUrl11() {
   
    const URL_EXPIRATION_TIME = 900;
  
                  const params = {
                    Bucket: "globldata",
                    Key: getpoddetails.picture,
                    Expires: URL_EXPIRATION_TIME,
                  }

               
                 
               myBucket.getSignedUrl('getObject', params, (err, url) => {
  
              
                setAuthImage(url);
  
              });
  }

  useEffect((token) => {


    const body = {
      "clientID": user.data[0].emp_id
    };

    const headers = {
      'Authorization' : token, // Replace with your actual token
      'Content-Type': 'application/json', // You can add more headers as needed
    };
    // https://xujexobo25.execute-api.us-east-1.amazonaws.com/dev/getcandidatepods
    axios.post(process.env.REACT_APP_CLIENT_GETCLIENTPOD, body,{headers:headers})

      .then((res) => {
      
        setError(res)
        if(res.data.data != undefined){
          setGetpoddetailsdata(res.data.data)
        }
        
        if(res.data.data.candidates != undefined){
         
          setGetcandidates(res.data.data.candidates)
        }
        
        if(res.data.data.podDetails != undefined){
          setGetpoddetails(res.data.data.podDetails)
          // res.data.data.podDetails.map((item)=>{
          //   setGetpoddetails(item)
          //   setId(item.id)
          //   setPodskill(JSON.parse(item.skills))
          // });
          
        }
        
       

        const URL_EXPIRATION_TIME = 900;
        
  
        const params = {
          Bucket: "globldata",
          Key: res.data.data.podDetails.picture,
          Expires: URL_EXPIRATION_TIME,
        }

        // console.log(params)
       
     myBucket.getSignedUrl('getObject', params, (err, url) => {

      console.log(err, url)
      setAuthImage(url);

    });


      }

      ).catch((err) => {
        console.log("catch", err);
      })


  }, [token]);

let activatePod = (podID) => {

  let confirm = window.confirm("Do you want to activate the pod") 

  if (confirm == true) {
 
    let body = {
      podID : podID
    }

    const headers = {
      'Authorization' : token, // Replace with your actual token
      'Content-Type': 'application/json', // You can add more headers as needed
    };
  // https://8u3olbqb53.execute-api.us-east-1.amazonaws.com/dev/activatepod
  axios.post(process.env.REACT_APP_CLIENT_ACTIVATEPOD, body,  {headers:headers}).then((res) => {

   if(res){
    alert(res.data.data);
    window.location.reload();
   }

  }


  )
  .catch((err) => {
    console.log("catch", err);
  })


}

}


let deactivatePod = (podID) => {

  let confirm = window.confirm("Do you want to Deactivate the pod") 

  if (confirm == true) {
 
    let body = {
      podID : podID
    }

    const headers = {
      'Authorization' : token, // Replace with your actual token
      'Content-Type': 'application/json', // You can add more headers as needed
    };
    
    // https://786508x3od.execute-api.us-east-1.amazonaws.com/dev/deactivatepod
  axios.post(process.env.REACT_APP_CLIENT_DEACTIVATEPOD, body,{headers:headers}).then((res) => {

   if(res){
    alert(res.data.data);
    window.location.reload();

   }

  }


  )
  .catch((err) => {
    console.log("catch", err);
  })


}

}


// console.log(getcandidates)

let deletepod = (idd) => {



 
 
    let body = {
      pod_id : idd
    }
    const headers = {
      'Authorization' : token, // Replace with your actual token
      'Content-Type': 'application/json', // You can add more headers as needed
    };
    
    // https://f9rtfk5g7i.execute-api.us-east-1.amazonaws.com/dev/deletepod
  axios.post(process.env.REACT_APP_CLIENT_DELETEPOD, body,{headers:headers}).then((res) => {

   if(res){
    alert('are you sure you want to delete pod');
     window.location.reload();
   }

  }


  )
  .catch((err) => {
    console.log("catch", err);
  })


  
  // https://xbf999f84g.execute-api.us-east-1.amazonaws.com/dev/delete_pod_candidate
  axios.post(process.env.REACT_APP_CLIENT_DELETEPODCANDIDATE, body,{headers:headers}).then((res) => {

  if(res){
  
    window.location.reload();
  }

 }


 )
 .catch((err) => {
   console.log("catch", err);
 })



}




  const intervals = [
    { label: 'year', seconds: 31536000 },
    { label: 'month', seconds: 2592000 },
    { label: 'day', seconds: 86400 },
    { label: 'hour', seconds: 3600 },
    { label: 'minute', seconds: 60 },
    { label: 'second', seconds: 1 }
  ];
  function timeSince(date) {
    const seconds = Math.floor((Date.now() - date.getTime()) / 1000);
    const interval = intervals.find(i => i.seconds < seconds);
    const count = Math.floor(seconds / interval.seconds);
    return `${count} ${interval.label}${count !== 1 ? 's' : ''} ago`;


  }

  



  return (
    <Div>
       
      <TitleText>Your Pod </TitleText>
      
      <InputContainer>
          
          <Icon>
              <FiSearch/>
              </Icon>
              <Input type="text" placeholder="Search for projects" />
      </InputContainer>
      <a href='/client-createpod'>
                                     
        <CreateButton >Create Pod</CreateButton>
                </a>
     
              <br/><br/>
      <RecommendProject>
      
      
        <CardContent>
          

          {/*  
      <Divseperator > */}


{ !getpoddetails && getpoddetails.length == 0 ? (<>  You Don't have any Pod </>) : (<>

                                                               
  {getpoddetails.map((getpoddetails)=>(<>
  <Row>

            <Detail>
              <InfoContainer>
              {AuthImage==null ? (
                <img
  src={defaultimg}
  alt=""
  style={{ width: 50, height: 50  }}
></img>
)


  :(
    <img
                  // src={'https://prismpoddata.s3.amazonaws.com/'+getpoddetails.picture}
                  src={AuthImage}
                  alt=""
                  style={{ width: 50, height: 50 }}
                ></img>
) }

               
                <InfoPod>
                  <InfoName>{getpoddetails.podName}</InfoName>
                 
                  {/* <InfoUpdate> {timeSince(new Date(item.createdAt))} </InfoUpdate> */}
                </InfoPod>
                {/* <a href='/ManagePod'>
                <ManageButton  onClick={()=>{
                  setManagePod(true)
                  setManagePodId(getpoddetails.id)
                  }} >Manage Pod</ManageButton>
                </a> */}
                <ManageButton onClick={()=>{
                  setManagePod(true)
                  setManagePodId(getpoddetails.id)
                  }} >Manage Pod</ManageButton>
                
                
              </InfoContainer>
             
            </Detail>
            
          </Row>
          <br></br>

          <Poddetails >
            <PodDiv>
              <Title>
                <Position>E-mail</Position> <span style={{ marginLeft: '2.5rem', marginRight: "1rem" }}>:</span>
                <Answer1>{getpoddetails.email} </Answer1>





              </Title>

              <Title>
                <Position>Portfolio</Position> <span style={{ marginLeft: '2.5rem', marginRight: "1rem" }}>:</span>
                <Answer> {getpoddetails.portfolioUrl}</Answer>

              </Title>
              <Title>
                <Position>Pod Size</Position> <span style={{ marginLeft: '2.5rem', marginRight: "1rem" }}>:</span>
               
                <Answer>{getpoddetails.podsize} </Answer>
              </Title>
            
              <Title>
                <Position>Skills </Position> <span style={{ marginLeft: '2.5rem', marginRight: "1rem" }}>:</span>
                <Answer>  {JSON.parse(getpoddetails.skills).map((eryt) => {
                  return <span style={{ margin: '5px' }}> {eryt.label}<span>,</span> </span>
                })
                }</Answer>

              </Title>
              {/* <Title>
                <Position>Budget</Position> <span style={{ marginLeft: '2.5rem', marginRight: "1rem" }}>:</span>
                <Answer>
                  {getpoddetails.budget}
                </Answer>

              </Title> */}

              <br></br>
              <br></br>
              <br></br>
              



{ getpoddetails.status == "active" ? ( <button class="btn btn-danger" onClick={() => { deactivatePod(getpoddetails.id) }}  >Deactivate Pod</button>  ) : ( <button class="btn btn-warning" onClick={() => { activatePod(getpoddetails.id) }}>Activate Pod</button> )}
              
             

<ManageButton1  onClick={()=>{deletepod(getpoddetails.id)}}>Delete Pod</ManageButton1>


            </PodDiv>




<Candidatesdetails>



  <p>Pod Team Members</p>
  <br></br>
  <div>
{getcandidates.length > 0 ?
getcandidates.map((data)=><>
<PositionCand1>{data.firstName} <span style={{paddingLeft:'2px'}}>{data.lastName}</span></PositionCand1>
 
{data.podID == getpoddetails.id &&<Title style={{ marging : "10px" }}>
{data.profilePicture=="" ? (
                <img
  src={defaultimg}
  alt=""
  style={{ width: 40, height: 40, borderRadius : "50px"  }}
></img>
)


  :(
    <img
    src={'https://globldata.s3.amazonaws.com/'+ data.profilePicture}
    alt=""
    style={{ width: 40, height: 40, borderRadius : "50px"  }}
  ></img>
) }
  <Colum>
  <PositionCand1>{data.firstName} <span style={{paddingLeft:'2px'}}>{data.lastName}</span></PositionCand1>
 
 
 {/* <PositionCand>{data.work_position}</PositionCand> */}
 </Colum>
</Title>}</>
) : ("No Candidates are Added")}

<Button2 onClick={()=>{
                  setModal(true)
               
                  }} >Invite</Button2>

<Button2 onClick={()=>{
                  setStakemodal(true)
               
                  }} >Invite Stakeholeer</Button2>
</div>
</Candidatesdetails>
{stakemodal  && <>
            <Modal
            open={stakemodal}
            onClose={()=>{setStakemodal(false)}}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <Stakeholderinvite  />
              </Box>
            </Modal>
          </>}

                

              {modal  && <>
            <Modal
            open={modal}
            onClose={()=>{setModal(false)}}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <Popup  />
              </Box>
            </Modal>
          </>}
          
          </Poddetails>
          {managepod && (managePodId == getpoddetails.id) && <>
            <Modal
            open={managepod}
            onClose={()=>{setManagePod(false)}}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <ClientManagePod pod_id={getpoddetails.id} />
              </Box>
            </Modal>
          </>}
          <br/><hr/><br/></>))}
          </>)}


          {/* </Divseperator> */}


        </CardContent>

   

        


        <SideCard>
{/* <H1>Recommended Pods</H1>
  <SideCardContent>
    
<Row>
 
            <Detail>
              <InfoContainer>
                <img
                  src={Avatar}
                  alt=""
                  style={{ width: 50, height: 50 }}
                ></img>
                <Info>
                  <InfoName>Thomas Martin</InfoName>
                  <InfoUpdate>10 days ago</InfoUpdate>
                </Info>
              </InfoContainer>
            </Detail>
          </Row>
      

          <Title>
            Need a designer to form branding essentials for my bussiness

            
          </Title>
          <ProjectInfo>
          Required Position: UX/UI Designer
          </ProjectInfo>

          </SideCardContent>
         <ViewAll>View All</ViewAll> */}
         <SideCardContent1>

<Title>
           Post Pod Activity
            
          </Title>

         
          <View>
            
       <NavHashLink to = '/Statusupdate'  smooth>
       
            Post</NavHashLink> 
            
             </View>
             </SideCardContent1>



             
  <SideCardContent1>

  


          <Title>
          Your Posted Pod Requirements
            
          </Title>
          <View>
            
       <NavHashLink to = '/ViewPostedProReq'  smooth>
       
            View</NavHashLink> 
            
             </View>

            
             
            
                 
         
          </SideCardContent1>
          <NavHashLink to = '/PostRequirementCand'  smooth>
            <Button >
          Post Requirement
          </Button> 
          </NavHashLink> 
</SideCard>


      </RecommendProject>
    </Div>
  );
};

const Div = styled.div`
  // display:flex;
  display: inline;
  jutify-content: center;
  flex-direction: column;
  margin-top: 6rem;
  align-items: center;

  margin-left: 6.5rem;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    display: flex;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    margin: auto;
    width: 25rem;
  }
`;

const CreateButton=styled.button`
display:flex;
justify-content:center;
align-items:center;
padding:5px;
width:10rem;
height:3rem;
font-size:18px;
font-weight:600;
color:white;
border:none;
border-radius:5px  ;
background-color:#1DBF73;
box-shadow: 1px 3px 6px #00000029;
margin-top:0rem;
margin-bottom:-2rem;

`;

const Div1 = styled.div`
  display: flex;

  jutify-content: center;
  flex-direction: row;

  align-items: center;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    display: flex;
    flex-direction: column;
  }
`;

const ButtonEdit = styled.button`

  text-decoration: none;
  border: none;
  margin-left: auto;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    display: flex;
    flex-direction: column;

    margin: auto;
    margin-top:2rem;
  }

`;
const RecommendProject = styled.div`

 display:flex;

jutify-content:center;
flex-direction:row;
@media screen and (min-width:320px) and (max-width:1080px){
    width:100%;
    height:max-content;
    margin:auto;
    
 display:flex;


 flex-direction:column;
    
   }
`;

const Row = styled.div`
  display: flex;

  align-item: center;
  margin-bottom: 0.8rem;
 
`;
const TitleText = styled.h4`
  font-weight: 600;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
   margin-top:2rem;
  }

`;

const Position = styled.p`

width:5rem;
font-size:15px;
  font-weight: 600;
  overflow-wrap: anywhere;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    width:4rem;
  }
`;


const Position1 = styled.p`
  font-weight: 600;
  width:9rem;
  font-size:15px;

  overflow-wrap: anywhere;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    width:4rem;
  }
`;



const Answer = styled.p`
  font-weight: 600;
  width:15rem;
  font-size:15px;
  overflow-wrap: anywhere;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    width:7rem;
    overflow-wrap: anywhere;
  }
`;


const Answer1 = styled.p`
  font-weight: 600;
  width:16rem;
  font-size:15px;
  overflow-wrap: anywhere;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    width:7rem;
    overflow-wrap: anywhere;
  }
`;


const CardContent = styled.div`
  margin: 0.4rem;
  width: 40rem;
  padding:1rem;
  
  height:max-height;

  border-radius:15px;
  font-family:Poppins,Medium;
  background-color:#F2F4F5;

  @media screen and (min-width: 320px) and (max-width: 1080px) {
    width: 20rem;
    margin:auto;
  }
`;
const Divseperator = styled.div`
// border-bottom:1px solid #CECECE;
padding:1rem;
`;
const Detail = styled.div`
  display: flex;
  justify-content: space-around;
  aligh-items: center;

  @media screen and (min-width: 320px) and (max-width: 1080px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 0.2rem;
    margin-bottom: 1rem;
  }
`;
const InfoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

`;

const Info = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-left: 15px;
`;
const InfoPod = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  margin-left: 15px;
`;


const InfoName = styled.h6`
  font-weight: 600;
`;
const InfoUpdate = styled.span`
  font-size: 13px;
  font-weight: 600;
  margin-right: auto;
`;
const PriceContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 600;
  padding: 0.3rem;

  border-radius: 7px;
  height: 100%;
  width: max-width;
  background-color: ${themeColor};

  @media screen and (min-width: 320px) and (max-width: 1080px) {
    flex-direction: column;
    // gap:0.4rem;
    width: 60%;
    height: 100%;
    margin-right: auto;
  }
`;
const Price = styled.div`
  color: white;
  width: max-width;
  padding-right: 10px;
`;
const Title = styled.h6`

  font-weight: 600;
  display:flex;
  flex-direction:row;
`;
const ProjectInfo = styled.h6`
font-weight: 600;
  display:flex;
  flex-direction:row;
`;

const SideCard = styled.div`
margin-left:5rem;
display:flex;
justify-content:center;
align-items:center;
flex-direction:column;
border-radius:20px;
font-family:Poppins,Medium;
width:20rem;
height:34rem;

padding: 1rem;
background-color: #F2F4F5;

box-shadow:${cardShadow};
transition:0.45 ease-in-out;
&:hover {
box-shadow:${hoverEffect};

}
@media screen and (min-width: 320px) and (max-width: 1080px) {
  width:20rem;

  margin: auto;
  margin-top:2rem;
}
`;


const SideCardContent = styled.div`
background-color:#90E0EF;
width:17rem;

display:flex;
flex-direction:column;
padding:3px;
margin:1rem;

border-radius:20px;
`;


const SideCardContent1 = styled.div`
background-color:#90E0EF;
width:17rem;
display:flex;
margin-top:1rem;
flex-direction:column;
height:10rem;
justify-content:center;
align-items:center;
border-radius:20px;
`;





const Detail1 = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;

  @media screen and (min-width: 320px) and (max-width: 1080px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 0.2rem;
    margin-bottom: 1rem;
  }
`;
const InfoContainer1 = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Info1 = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-left: 15px;
`;
const Row1 = styled.div`
  display: flex;

  align-item: center;
  margin-bottom: 0.8rem;
`;
const InfoName1 = styled.h6`
  font-weight: 600;
`;
const InfoUpdate1 = styled.span`
  font-size: 13px;
  font-weight: 600;
  margin-right: auto;
`;

const Title1 = styled.h6`
  font-weight: 600;
`;
const ProjectInfo1 = styled.p`
padding:5px;
margin:3px;
background-color:white;
border-radius:5px  ;
font-size:13px;
color:black;
font-weight:600;
margin-bottom:1rem
`;

const ViewAll = styled.button`
width:5rem;
font-weight:600;
height:2rem;
margin-left:auto;
margin-top:1rem;
border: 2px solid #1DBF73;
border-radius:5px  ;
box-shadow: 1px 3px 6px #00000029;
`;

const View = styled.button`
width:8rem;
font-weight:600;
height:2rem;
background-color:white;
border-radius:5px  ;
margin-bottom:1rem;
margin-top:1rem;
border: none;
border-radius:5px  ;
box-shadow: 1px 3px 6px #00000029;
`;


const Poddetails = styled.div`
display:flex;
flex-direction:row;
@media screen and (min-width: 320px) and (max-width: 1080px) {
  flex-direction: column;
  justify-content: flex-start;
 
}
`;



const PodDiv  = styled.div`

`;

const Candidatesdetails  = styled.div`
height:12rem;
padding:5px;
width:14rem;
border-radius:15px;
height:20rem;
background-color:rgb(166, 194, 206,30%);
overflow-y: hidden;
overflow-x: hidden;
margin-top:2rem;
@media screen and (min-width: 320px) and (max-width: 1080px) {
    width: 18rem;

  }
`;

const Colum  = styled.div`

margin-left:10px;
`;


const PositionCand = styled.p`
  font-weight: 600;
 width:max-content;
 width:11rem;
  font-size:15px;
margin-top:-1rem;
  overflow-wrap: anywhere;
  @media screen and (min-width: 320px) and (max-width: 1080px) {

  }

  `;

  const PositionCand1 = styled.p`
  font-weight: 600;
 width:11rem;
  font-size:15px;

  overflow-wrap: anywhere;
  @media screen and (min-width: 320px) and (max-width: 1080px) {

  }

  `;

  const H1 = styled.h1`
font-size:22px;
font-weight:600;
margin-bottom:1.2rem;
`;


const Button=styled.button`
margin-top:1rem;
border:none;
border-radius: 10px;
background-color:#1DBF73;
box-shadow: 1px 3px 6px #00000029;
width:12rem;
height:2rem;
font-size:15px;
font-weight:600;
color:white;

`;

const InputContainer = styled.div`
display : flex;
float:right;
border-radius:10px;
justify-content: center;
margin-left:auto;
width:25rem;
margin-top:-2rem;
margin-bottom:1rem;
border:1px solid #4E8FAE;
box-shadow: 3px 6px 9px #00000005;
@media screen and (min-width:320px) and (max-width:1080px){
 margin:auto;
    margin-top:2rem;
 margin-bottom:2rem;
 width: 18rem;
}
`;

const Icon = styled.div`
height: 3rem;
width: 2rem;
background-color: #F2F3F4;
display:flex;
flex-direction:row
justify-content: center;
align-items: center;
border-top-left-radius:10px;
border-bottom-left-radius:10px;
svg{
    color:#555555;
}
`;

const Input = styled.input`
justify-content: center;
height: 3rem;
width: 25rem;
border:0px solid #4E8FAE;
background-color:#F2F3F4;
font-family:Poppins,Medium;
font-Weight:600;
border-top-right-radius:10px;
border-bottom-right-radius:10px;
&:focus{
    border:none;
    outline:none;
}
@media screen and (min-width:320px) and (max-width:1080px){
  width: 18rem;
}
`;
const ManageButton=styled.button`
display:flex;
justify-content:center;
align-items:center;
margin-left:15rem;
padding:5px;
width:10rem;
height:2rem;
font-size:18px;
font-weight:600;
color:white;
border:none;
border-radius:5px  ;
background-color:#1DBF73;
box-shadow: 1px 3px 6px #00000029;
@media screen and (min-width: 320px) and (max-width: 1080px) {
  margin-left:5rem;
  width:5rem;
  height:3rem;
  font-size:12px;
 }



`;

const ManageButton1=styled.button`
display:flex;
justify-content:center;
align-items:center;
margin-top:1rem;
padding:5px;
width:10rem;
height:2rem;
font-size:18px;
font-weight:600;
color:white;
border:none;
border-radius:5px  ;
background-color:#00B4D8;
box-shadow: 1px 3px 6px #00000029;
@media screen and (min-width: 320px) and (max-width: 1080px) {
  margin-left:5rem;
  width:5rem;
  height:3rem;
  font-size:12px;
 }



`;
const Button2 = styled.button`
font-family:Poppins,Medium;
    background-color: #00B4D8;
    color: white;
   font-size: 15px;
     border-radius: 50px;
     height: 30px;
        width:5rem;
        margin-left:2rem;
        
border:none;
    font-weight: 600;
    font-family: Poppins,Medium;
    box-shadow: 1px 3px 6px #00000029;
    @media screen and (min-width:320px) and (max-width:1080px){
        width:10rem;
        height:max-height;
        margin:auto;
        }
`;



export default ClientPods
