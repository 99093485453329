import { NavLink, useHistory } from 'react-router-dom'
import styled ,{ css }from 'styled-components'
import React from "react";
import Badge from "./Badge";
import Avatar from "./assets/MaskGroup.png";
import { cardShadow, hoverEffect, themeColor } from "../utils";
import { useState } from "react";
import ShowMore from "react-show-more-button/dist/components/ShowMore";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Rsvp } from "@mui/icons-material";
import axios from "axios";
import { data, error, getJSON } from "jquery";
import { Route } from "react-router-dom";
import { NavHashLink } from 'react-router-hash-link';
import FindPods from "./FindPods";
import Reviewsandratingcandidate from './Reviewsandratingcandidate';
// import EditPostRequirementCand from '../EditPostRequirementCand';
import star from './assets/star.png';
import AWS from 'aws-sdk';
import { FaStar,FaStarHalfAlt } from 'react-icons/fa';
import { AiOutlineStar } from 'react-icons/ai';

import defaultimg from './assets/defaultimge.png';
import { useAuthToken } from "../TokenContext";

const S3Config = {
  region: process.env.REACT_APP_REGION,
  accessKeyId: process.env.REACT_APP_ACCESSKEYID,
  secretAccessKey: process.env.REACT_APP_SECRETACCESSKEY
}

const ReviewsandratingPodcandidate = () => {
  const token = useAuthToken();


    const { isLoggedIn, user } = useSelector(state => state.auth);
    
    const[getdata,setGetData]=useState([]);

    const [Starrating,setStarrating]=useState();
  const primaryskill = user.data[0].primaryskill
  const secondaryskill = user.data[0].secondaryskill
const About= user.data[0].about
const certification= user.data[0].certification
 const certificationIntitution = user.data[0].certificationInstitution

 const myBucket = new AWS.S3(S3Config);

           
  function generatePreSignedPutUrl11(picture) {
   
    const URL_EXPIRATION_TIME = 900;

    var authurl;

  
                  const params = {
                    Bucket: process.env.REACT_APP_BUCKETNAME,
                    Key: picture,
                    Expires: URL_EXPIRATION_TIME,
                  }

                
                 
               myBucket.getSignedUrl('getObject', params, (err, url) => {

            
  
              });

              return "url";

  }


   useEffect(() =>{

    const headers = {
      'Authorization' : token, // Replace with your actual token
      'Content-Type': 'application/json', // You can add more headers as needed
    };
const body={"candidateID": user.data[0].c_id} ;

// https://c0jmvgzxed.execute-api.us-east-1.amazonaws.com/dev/getpodreview
    axios.post(process.env.REACT_APP_TALENT_GETPODREVIEW,body,{headers:headers})

      .then((res)=>    { 
        res.data.data.map((result)=> {
         
          setStarrating(result.rating)
      })

if(res.data.data == "There Are No Reviews."){
  setGetData([]);
}else{

      setGetData(res.data.data);
     
    }
    
   
        
      })
      .catch((err)=>{
        console.log("catch",err);
      })

   },[token]);




         

   
   
    
  
  
         const intervals = [
          { label: 'year', seconds: 31536000 },
          { label: 'month', seconds: 2592000 },
          { label: 'day', seconds: 86400 },
          { label: 'hour', seconds: 3600 },
          { label: 'minute', seconds: 60 },
          { label: 'second', seconds: 1 }
        ];
        function timeSince(date) {
          const seconds = Math.floor((Date.now() - date.getTime()) / 1000);
          const interval = intervals.find(i => i.seconds < seconds);
          const count = Math.floor(seconds / interval.seconds);
          return `${count} ${interval.label}${count !== 1 ? 's' : ''} ago`;
        
      
        }

        const ratingStar=Array.from({length:5},(elem,index)=>{
          let number =index + 0.5;
          return(
            <span key={index}>
            {  Starrating>=index+1?
              ( <FaStar/>): Starrating>=number?( <FaStarHalfAlt/>):( <AiOutlineStar/>)}
            </span>
          )
        } )
        
    
//   console.log(rep);

 

  return (
    <Div>
      <TitleText>Review & Rating</TitleText>
      <Navdiv>

<Cast to='/Reviewsandratingcandidate'   >Individual </Cast>
<span> <Cast2 to='/ReviewsandratingPodcandidate'  >Pod</Cast2></span>

</Navdiv>
      <RecommendProject>
      <CardContent>
      { getdata.length > 0 ?  
  getdata.map((item)=> 
   
 
      <Divseperator >
  
        

 
          <Row>
            <Detail>
              <InfoContainer>

              {item.profilePicture==null ? (
                <img
  src={defaultimg}
  alt=""
  style={{ width: 50, height: 50, borderRadius : "50px"  }}
></img>
)


  :(
    <img
    src={'https://globldata.s3.amazonaws.com/'+ item.profilePicture}
    alt=""
    style={{ width: 50, height: 50, borderRadius : "50px"  }}
  ></img>
) }
  

 
               
                <Info>
                  <InfoName>{item.podName} <span style={{marginLeft:"1.5rem",fontSize:'13px',fontWeight:"bold",color:"orange"}}>    {ratingStar}</span></InfoName>
                  <InfoUpdate> {timeSince(new Date(item.createdAt))} </InfoUpdate>
                
                </Info>
              </InfoContainer>
            </Detail>
          </Row>
      
          <p><b>Client Name</b> : {item.emp_firstname + " " + item.emp_lastname} </p>
         
          <ProjectInfo>
         

          <Position1> {item.review}</Position1> 
         
         
          
          </ProjectInfo>
          <Div1>
          


            
            
           
                 
                 
       
            <ButtonEdit 
            
             
            >
 {/* <NavHashLink to = {`/EditPostRequirementCand/${item.id}`}  smooth>
            
 <Badge content="Edit" glow1 />     </NavHashLink>  */}

             
            </ButtonEdit>
            
          </Div1>
         
         
          {/* {show ? <p> hello miss !</p> : null} */}
          </Divseperator>
               
           ) : (<>There are no pod reviews. </>)}  
        </CardContent>
    
 
        
        
        {/* <SideCard>
        
  <SideCardContent>

  <Title1>
           About
            
          </Title1>

          <ABOUT>{About}</ABOUT>
        

          </SideCardContent>
       

       
          <SideCardContent>

<Title1>
         Skills
          
        </Title1>
 
        <Price1>

 <Price>
 {primaryskill} 
              </Price>
              <Price2>
 {secondaryskill} 
              </Price2>
             
              </Price1>
        
        </SideCardContent>
          
         <SideCardContent>
         <Title1>
    Certificates
          
        </Title1>
<Certificatetab>
      <CertificateName>
        {certification}
        </CertificateName>


        <CertificateNameinsti>
        {certificationIntitution}
        </CertificateNameinsti>
        

          </Certificatetab>
          </SideCardContent>

        
</SideCard> */}


      </RecommendProject>
    </Div>
  );
};

const Div = styled.div`
  // display:flex;
  display: inline;
  jutify-content: center;
  flex-direction: column;
  margin-top: 6rem;
  align-items: center;

  margin-left: 6.5rem;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    display: flex;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    margin: auto;
    width: 25rem;
  }
`;
const Div1 = styled.div`
  display: flex;

  jutify-content: center;
  flex-direction: row;

  align-items: center;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    display: flex;
    flex-direction: column;
  }
`;

const Navdiv=styled.div`
margin:1rem ;
@media screen and (min-width:320px) and (max-width:1080px){
 

}
 `;




 const CSSstyle=css`
 margin-left: 22rem; 
 color: black;
 background-color: #E4E4E4;
 &:hover 
 {
  background-color:#E4E4E4;;
}
 text-decoration: none;
  border-radius: 2rem; 
 padding: 0.5rem;
 padding-left: 2rem;
 padding-right: 3.5rem;
 @media screen and (min-width:320px) and (max-width:1080px){
  
  margin-left: 3rem; 
  padding-left: 2rem;
  padding-right: none;
 }
 `;


 const CSSstyle1=css`
 pointer-events: none;
 color: white;
  box-shadow: 1px  3px 6px  #00000029; 
 background-color: #00B4D8; 
 text-decoration: none;
  border-radius: 2rem;
 padding: 0.5rem;
  margin-left: -3rem;
 padding-left: 3.5rem;
  padding-right: 3.5rem;

  @media screen and (min-width:320px) and (max-width:1080px){
   
   }
   `;



   const Cast2=styled(NavLink)`
   ${CSSstyle1}
   `;
  

 const Cast=styled(NavLink)`
 ${CSSstyle}
 `;


const ButtonEdit =styled.button`

  text-decoration: none;
  border: none;
  margin-left: auto;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    display: flex;
    flex-direction: column;

    margin: auto;
    margin-top:2rem;
  }

`;
const RecommendProject=styled.div`

 display:flex;

jutify-content:center;
flex-direction:row;
@media screen and (min-width:320px) and (max-width:1080px){
    width:100%;
    height:max-content;
    margin:auto;
    
 display:flex;


 flex-direction:column;
    
   }
`;

const Row = styled.div`
  display: flex;

  align-item: center;
  margin-bottom: 0.8rem;
`;
const TitleText = styled.h4`
  font-weight: 600;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
   margin-top:2rem;
  }

`;

const Position = styled.p`
  font-weight: 600;
  overflow-wrap: anywhere;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    width:4rem;
  }
`;


const Position1 = styled.p`
  font-weight: 600;
  width:15rem;
  overflow-wrap: anywhere;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    width:17rem;
    overflow-wrap: anywhere;
  }
`;



const Answer = styled.p`
  font-weight: 600;
  width:15rem;
  overflow-wrap: anywhere;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    width:7rem;
    overflow-wrap: anywhere;
  }
`;



const CardContent = styled.div`
  margin: 0.4rem;
  width: 40rem;
  padding:1rem;
  
  height:max-content;
  border-radius:10px;
  font-family:Poppins,Medium;
  background-color:#F2F4F5;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    width: 22rem;
    
  margin: auto;
  }
`;
const Divseperator = styled.div`
border-bottom:1px solid #CECECE;
padding:1rem;
`;
const Detail = styled.div`
  display: flex;
  justify-content: space-around;
  aligh-items: center;

  @media screen and (min-width: 320px) and (max-width: 1080px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 0.2rem;
    margin-bottom: 1rem;
  }
`;
const InfoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Info = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-left: 15px;
`;
const InfoName = styled.h6`
  font-weight: 600;
`;
const InfoUpdate = styled.span`
  font-size: 13px;
  font-weight: 600;
  margin-right: auto;
`;
const PriceContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 600;
  padding: 0.3rem;

  border-radius: 7px;
  height: 100%;
  width: max-width;
  background-color: ${themeColor};

  @media screen and (min-width: 320px) and (max-width: 1080px) {
    flex-direction: column;
    // gap:0.4rem;
    width: 60%;
    height: 100%;
    margin-right: auto;
  }
`;
const Price = styled.p`
  color: black;
  border:1px solid #1DBF73;
  border-radius:10px;
width:max-width;
  padding: 3px;
  font-weight:600;
  font-family:Poppins, Medium;
`;

const Price2 = styled.p`
  color: black;
  border:1px solid #1DBF73;
  border-radius:10px;
width:max-width;
  padding: 3px;
  margin-left:10px;
  font-weight:600;
  font-family:Poppins, Medium;
`;
const Price1 = styled.div`
  display:flex;
  flex-direction:row;
  margin-top:10px;
`;


const ABOUT = styled.p`
color: black;

font-family:Poppins, Medium;
padding: 3px;

font-weight:600;
`;
const Certificatetab = styled.div`
display:flex;
flex-direction:column;
margin-top:10px;
`;


const CertificateName = styled.p`
color: black;

font-family:Poppins, Medium;

 
  font-weight:600;
`;


const CertificateNameinsti = styled.p`
color: black;
font-family:Poppins, Medium;
font-weight:600;
font-size:12px;
margin-top:-5px;

 
  font-weight:600;

`;





const Title = styled.h6`
  font-weight: 600;
  display:flex;
  flex-direction:row;
`;
const ProjectInfo = styled.h6`
font-weight: 600;
  display:flex;
  flex-direction:row;
`;

const SideCard=styled.div`
margin-left:5rem;
display:flex;
justify-content:center;
align-items:center;
flex-direction:column;
border-radius:20px;
font-family:Poppins, Medium;
width:20rem;
height:38rem;

padding: 1rem;
background-color: #F2F4F5;

box-shadow:${cardShadow};
transition:0.45 ease-in-out;
&:hover {
box-shadow:${hoverEffect};

}
@media screen and (min-width: 320px) and (max-width: 1080px) {
  width:22rem;

  margin: auto;
  margin-top:2rem;
}
`;


const SideCardContent=styled.div`

width:17rem;
height:12.66rem;
border-bottom:1px solid #CECECE;
display:flex;
flex-direction:column;
padding:3px;



`;







const Detail1 = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;

  @media screen and (min-width: 320px) and (max-width: 1080px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 0.2rem;
    margin-bottom: 1rem;
  }
`;
const InfoContainer1 = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Info1 = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-left: 15px;
`;
const Row1= styled.div`
  display: flex;

  align-item: center;
  margin-bottom: 0.8rem;
`;
const InfoName1 = styled.h6`
  font-weight: 600;
`;
const InfoUpdate1 = styled.span`
  font-size: 13px;
  font-weight: 600;
  margin-right: auto;
`;

const Title1 = styled.h6`
  font-weight: 600;
`;
const ProjectInfo1 = styled.p`
padding:5px;
margin:3px;
background-color:white;
border-radius:5px  ;
font-size:13px;
color:black;
font-weight:600;
margin-bottom:1rem
`;

const ViewAll =styled.button`
width:5rem;
font-weight:600;
height:2rem;
margin-left:auto;
margin-top:1rem;
border: 2px solid #1DBF73;
border-radius:5px  ;
box-shadow: 1px 3px 6px #00000029;
`;

const View =styled.button`
width:8rem;
font-weight:600;
height:2rem;
background-color:white;
border-radius:5px  ;
margin-bottom:1rem;
margin-top:1rem;
border: none;
border-radius:5px  ;
box-shadow: 1px 3px 6px #00000029;
`;






export default ReviewsandratingPodcandidate
