import React, { useState } from 'react';
import styled from 'styled-components';
import Sidebarnew from './Sidebarnew'; // Adjust the path according to your file structure
import Dashboardnew from './Dashboardnew';
import AppBarIcons from './AppBarIcons'; // Adjust the path according to your file structure
import Talentdashboardpodactivity from './Talentdasboardpodactivity';
import InfoDummy from './Info';

const LayoutContainer = styled.div`
  display: flex;
  background-color: #f0f0f0;
  margin: 0;
  height: 100vh;
  margin-top:4rem;
  width: 100vw;
  overflow-y: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none; /* Hide scrollbar for Webkit browsers */
  }
`;

const ContentContainer = styled.div.attrs(props => ({
  'data-collapsed': props.collapsed.toString(),
}))`
  flex-grow: 1;
  overflow-y: auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  transition: margin-left 0.3s, width 0.3s;
  margin-left: ${(props) => (props['data-collapsed'] === 'true' ? '60px' : '240px')};
  width: ${(props) => (props['data-collapsed'] === 'true' ? 'calc(100% - 60px)' : 'calc(100% - 240px)')};
`;

const DashboardContainer = styled.div`
  margin-bottom: 20px; /* Separate the dashboard from the row container */
`;

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin:auto;
  padding: 20px;
  
   height:100%;
  @media (max-width: 768px) {
    flex-direction: column;
    margin: auto;
     height:auto;
  }
`;

const ContainerWithSameHeight = styled.div`
  width: 100%;
  overflow-y: auto;
  background-color: white;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
 height:90%;
  &::-webkit-scrollbar {
    display: none; /* Hide scrollbar for Webkit browsers */
  }
    @media (max-width: 768px) {
    flex-direction: column;
    margin: auto;
     height:auto;
  }
`;

const TalentdashboardContainer = styled(ContainerWithSameHeight)`
  width: 70%;
   margin-right: 10px;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3) !important;
  
  @media (max-width: 768px) {
    width: 100%;
    margin-bottom: 10px;
  }
`;

const InfoDummyContainer = styled(ContainerWithSameHeight)`
  width: 30%;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);

  @media (max-width: 768px) {
    width: 100%;
    margin-bottom: 10px;
  }
`;

const MainLayout = ({ collapsed }) => {
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const handleSidebarToggle = () => {
    setSidebarOpen((prev) => !prev);
  };

  return (
    <LayoutContainer>
      {/* <Sidebarnew open={sidebarOpen} /> */}
      <ContentContainer collapsed={collapsed}>
        {/* <AppBarIcons onMenuClick={handleSidebarToggle} /> */}
        <DashboardContainer>
          <Dashboardnew />
        </DashboardContainer>
        <RowContainer>
          <TalentdashboardContainer>
            <Talentdashboardpodactivity />
          </TalentdashboardContainer>
          <InfoDummyContainer>
            <InfoDummy />
          </InfoDummyContainer>
        </RowContainer>
      </ContentContainer>
    </LayoutContainer>
  );
};

export default MainLayout;
