import React, { useState } from 'react'
import styled from 'styled-components'

import "./FindPods.css";
import { useEffect } from 'react';
import axios from 'axios';

import {  useSelector } from "react-redux";
import { cardShadow, hoverEffect, themeColor } from "../utils";

import { NavHashLink } from 'react-router-hash-link';
import { Link } from 'react-router-dom';

import Podreviewrating from './Podreviewrating';

import ClientManagePod from './ClientManagePod';
import defaultimg from './assets/defaultimge.png';
import Popup from './Popup';
 import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import AWS from 'aws-sdk';

import TaskForm from './TaskForm';
import Mastergithub from './Mastergithub';
import { Breadcrumbs } from '@mui/material';

import Stakeholderinvite from './Stakeholderinvite';
import Popupstakeholdermanager from './Popupstakeholdermanager';
import { useAuthToken } from "../TokenContext";
import { useIntl } from 'react-intl';

const FindPodsDetails = () => {

  const token = useAuthToken();
  const intl = useIntl();

    const [AuthImage, setAuthImage] = useState(""); 
    const { isLoggedIn, user } = useSelector(state => state.auth);
    const [getpoddetails, setGetpoddetails] = useState({});
    const [getcandidates, setGetcandidates] = useState([]);
    const [getReq, setgetReq] = useState([]);
    const[popup,setPopup]=useState(false)
 const[removebutton,setRemovebutton]=useState(0);
    const[invite,setinvite]=useState([{emails: ''}]);
    const [podskill, setPodskill] = useState([]);
   const [modalId, setModalId] = useState(""); 
   const[modal,setModal]=useState(false);
    const[modalstake,setModalstake]=useState(false);
const[getid,setGetid]=useState('');
    const [getData, setGetData] = useState("")
const [candidateid,setCandidateid]=useState( "");
  const [managepod, setManagePod]=useState(false);
   const [managePodId, setManagePodId] = useState(""); 
    const email = user.data[0].email
    const[stakemodal,setStakemodal]=useState(false);

    const [task,setTask]=useState(false);
    const[setting,setSetting]=useState(false)
    const [taskid,setTaskid]=useState("");
    const[settingid,setSettingid]=useState('');
    const [selectedStakeholders, setSelectedStakeholders] = useState([]);
 
    const portfolioUrl = user.data[0].portfolioUrl
    
    const firstName = user.data[0].firstName
    
    const lastName = user.data[0].lastName
  
// const skilldata = (data) => {

//     var mainskill = JSON.parse(data)
//     console.log("1112423435345345 :::: ",mainskill)
//     return(mainskill);

// }
const S3Config = {
    region: "us-east-1",
    accessKeyId: 'AKIA6CNQFW6ISZIFRSNJ',
    secretAccessKey: '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'
  }
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'auto',
    height:"auto",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    overflow: 'auto',
  };

const myBucket = new AWS.S3(S3Config);

const id = localStorage.getItem('selectedItemId');

const handleinputchange=(e, index)=>{
  const {name, value}= e.target;
  const list= [...invite];
  list[index][name]= value;
  setinvite(list);
  

}


const handleremove= index=>{
  if(invite.length==1){
    alert('there should be atleast one update field')
    setRemovebutton(1)


  }else{
    const list=[...invite];
    list.splice(index,1);
    setinvite(list);
  }

}

 const handleaddclick=()=>{
  // setinputList([...inputList,{PodCandidate:'', update:'',Categories:''}]);
  if(invite.length>=3){
    alert("Max Limit is 3");

  }else{
   // console.log('data ;; ',[...invite,{emails:''}]);
    setinvite([...invite,{emails:''}]);
  }
 }



const handleSubmitinvite = (e) => {




  e.preventDefault();


  const requestOptionsinvite = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json','Authorization' : token, },
    body: JSON.stringify({invite , emp_id: user.data[0].emp_id })
  
};
// console.log('consoledata',invite)


var arr = invite

var clean = arr.filter((arr, index, self) =>
    index === self.findIndex((t) => (t.emails === arr.emails)))
   

if (invite.length !== clean.length )  {
 alert ('pod candidate name should be unique') ;
} 

 else{
  
  
fetch(process.env.REACT_APP_CLIENT_EMAILINVITE,requestOptionsinvite)
.then((response) => response.json())


.then((json) => { 
  

if(json.status == 200){
       alert("Invite Mail sent.")

       setTimeout(() => {
        window.location.reload();            
      }, 2000);
     }else if(json.status == 400){
      alert(json.data)
   
      setTimeout(() => {
       window.location.reload();            
     }, 2000);
    }
  
  });


 }


 };



    useEffect(() => {

      fetchPreFetchedStakeholders()
      
      const headers = {
        'Authorization' : token, // Replace with your actual token
        'Content-Type': 'application/json', // You can add more headers as needed
      };

        const body = {
           id: id
        };


        
        // https://w3ten9gqf7.execute-api.us-east-1.amazonaws.com/dev/getpodbyid
        axios.post(process.env.REACT_APP_CLIENT_GETPODID, body,{headers:headers}).then((res) => {
               
                setGetData(res.data.data);
                setGetid(res.data.data.podDetails.id)
               
                setgetReq(res.data.data.podDetails.requrment);


                setGetcandidates(res.data.data.candidates)
                setCandidateid(res.data.data.candidates.c_id)
                setGetpoddetails(res.data.data.podDetails)
                setPodskill(res.data.data.podDetails.skills)

               
           
                const URL_EXPIRATION_TIME = 900;
        
  
                const params = {
                  Bucket: "globldata",
                  Key: res.data.data.podDetails.picture,
                  Expires: URL_EXPIRATION_TIME,
                }
        
                // console.log(params)
               
             myBucket.getSignedUrl('getObject', params, (err, url) => {
        
              console.log(err, url)
              setAuthImage(url);
        
            });
           
            }

          
          

            ).catch((err) => {
                console.log("catch", err);
            })


    }, [token])


    const fetchPreFetchedStakeholders = () => {
      // Fetch pre-fetched stakeholder data
      console.log('idman',id)
      const headers = {
        'Authorization' : token, // Replace with your actual token
        'Content-Type': 'application/json', // You can add more headers as needed
      };
      axios.post(process.env.REACT_APP_CLIENT_GETPODSTAKEHOLDERSLIST, { "podID": id },{headers:headers})
        .then(response => {
          const fetchedData = response.data.data;
         
          setSelectedStakeholders(fetchedData.map(stakeholder => stakeholder.stakeholdername));
        })
        .catch(error => {
          console.error('Error fetching pre-fetched stakeholders:', error);
        });
    };

    let activatePod = (podID) => {

        let confirm = window.confirm("Do you want to activate the pod") 
      
        if (confirm == true) {
       
          let body = {
            podID : podID
          }
          const headers = {
            'Authorization' : token, // Replace with your actual token
            'Content-Type': 'application/json', // You can add more headers as needed
          };
        
        // https://8u3olbqb53.execute-api.us-east-1.amazonaws.com/dev/activatepod
        axios.post(process.env.REACT_APP_CLIENT_ACTIVATEPOD, body,{headers:headers}).then((res) => {
      
         if(res){
          alert(res.data.data);
          window.location.reload();
         }
      
        }
      
      
        )
        .catch((err) => {
          console.log("catch", err);
        })
      
      
      }
      
      }
      
      
      let deactivatePod = (podID) => {
      
        let confirm = window.confirm("Do you want to Deactivate the pod") 
      
        if (confirm == true) {
       
          let body = {
            podID : podID
          }
      
          const headers = {
            'Authorization' : token, // Replace with your actual token
            'Content-Type': 'application/json', // You can add more headers as needed
          };
          
          // https://786508x3od.execute-api.us-east-1.amazonaws.com/dev/deactivatepod
        axios.post(process.env.REACT_APP_CLIENT_DEACTIVATEPOD, body,{headers:headers}).then((res) => {
      
         if(res){
          alert(res.data.data);
          window.location.reload();
      
         }
      
        }
      
      
        )
        .catch((err) => {
          console.log("catch", err);
        })
      
      
      }
      
      }
      
      
     
      
      let deletepod = (idd) => {
      
        const headers = {
          'Authorization' : token, // Replace with your actual token
          'Content-Type': 'application/json', // You can add more headers as needed
        };
      
       
       
          let body = {
            pod_id : idd
          }
          
          // https://f9rtfk5g7i.execute-api.us-east-1.amazonaws.com/dev/deletepod
        axios.post(process.env.REACT_APP_CLIENT_DELETEPOD, body,{headers:headers}).then((res) => {
      
         if(res){
          alert('are you sure you want to delete pod');
           window.location.reload();
         }
      
        }
      
      
        )
        .catch((err) => {
          console.log("catch", err);
        })
      
      
        
        // https://xbf999f84g.execute-api.us-east-1.amazonaws.com/dev/delete_pod_candidate
        axios.post(process.env.REACT_APP_CLIENT_DELETEPODCANDIDATE, body,{headers:headers}).then((res) => {
      
        if(res){
        
          window.location.reload();
        }
      
       }
      
      
       )
       .catch((err) => {
         console.log("catch", err);
       })
      
      
      
      }


    return (
    <OL1>

      
 {/* <Headingtext1> Find the best pod and get registered or create your own pod</Headingtext1>

     */}
     

<Breadcrumbs aria-label="breadcrumb">
  <Link to="/Team" style={{ color: '#03045e', fontSize: "18px", fontFamily: 'Poppins,Medium', fontWeight: "600" }}>My Pods</Link>
  <span style={{ color: 'grey', fontSize: "18px", fontFamily: 'Poppins,Medium', fontWeight: "600" }}>&nbsp;&gt;&nbsp;</span>
  <span style={{ color: 'grey', fontSize: "18px", fontFamily: 'Poppins,Medium', fontWeight: "600" }}>View Pod</span>
</Breadcrumbs>
    <Div >
    <a href='/client-createpod'>
                                     
        <CreateButton >{intl.formatMessage({ id: 'createPod' })}</CreateButton>
                </a>
                
 <RecommendProject>   <CardContent> <Row>

            <Detail>
              <InfoContainer>
              {AuthImage==null ? (
                <img
  src={defaultimg}
  alt=""
  style={{ width: 50, height: 50  }}
></img>
)


  :(
    <img
                  // src={'https://prismpoddata.s3.amazonaws.com/'+getpoddetails.picture}
                  src={AuthImage}
                  alt=""
                  style={{ width: 50, height: 50 }}
                ></img>
) }

               
                <InfoPod>
                  <InfoName>{getpoddetails.podName}</InfoName>
                 
                  {/* <InfoUpdate> {timeSince(new Date(item.createdAt))} </InfoUpdate> */}
                </InfoPod>
                {/* <a href='/ManagePod'>
                <ManageButton  onClick={()=>{
                  setManagePod(true)
                  setManagePodId(getpoddetails.id)
                  }} >Manage Pod</ManageButton>
                </a> */}
              
                
                
              </InfoContainer>
             
            </Detail>
            
          </Row> 
            <br></br>
            <Poddetails >
            <PodDiv>
              <Title>
                <Position>{intl.formatMessage({ id: 'email' })}l</Position> <span style={{ marginLeft: '2.5rem', marginRight: "1rem" }}>:</span>
                <Answer1>{getpoddetails.email} </Answer1>





              </Title>

              
              <Title>
                <Position>{intl.formatMessage({ id: 'podSize' })}</Position> <span style={{ marginLeft: '2.5rem', marginRight: "1rem" }}>:</span>
               
                <Answer>{getpoddetails.podsize} </Answer>
              </Title>
            
              <Title>
                <Position>{intl.formatMessage({ id: 'skills' })} </Position> <span style={{ marginLeft: '2.5rem', marginRight: "1rem" }}>:</span>
                <Answer>  {podskill.map((eryt) => 
                <span style={{ margin: '5px' }}> {eryt.skill}<span>,</span> </span>
                )
                }</Answer>

              </Title>
              <Title>
                <Position  onClick={()=>{
                  setModalstake(true)
               
                  }} style={{cursor:'pointer',color:"#00B4D8"}}>{intl.formatMessage({ id: 'addStakeholder' })}</Position> <span style={{ marginLeft: '2.5rem', marginRight: "1rem" }}>:</span>
                <Answer>

               {selectedStakeholders > 0?selectedStakeholders.map((stakeholder)=>
 <span>{stakeholder}<span style={{paddingRight:'5px'}}>,</span></span>
               ):(intl.formatMessage({ id: 'noStakeholders' })
               )}
               
       
     
                </Answer>

              </Title>
              {modalstake  && <>
            <Modal
            open={modalstake}
            onClose={()=>{setModalstake(false)}}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <Popupstakeholdermanager pod_id={getpoddetails.id}  />
              </Box>
            </Modal>
          </>}
          
              <br></br>
              <br></br>
              <br></br>
            

              <NavHashLink to={{
                pathname: '/ClientManagePod',
                state: {
                    
                    podid: getpoddetails.id
                }
            }}
            smooth>
                <ManageButton   >{intl.formatMessage({ id: 'manage' })} </ManageButton>
                
                </NavHashLink>
              {/* <ManageButton onClick={()=>{
                  setManagePod(true)
                  setManagePodId(getpoddetails.id)
                  }} >Edit Pod</ManageButton> */}

{ getpoddetails.status == "Active" ? ( <button className="btn btn-danger" style={{width:'7.6rem',
height:'2.3rem'}} onClick={() => { deactivatePod(getpoddetails.id) }}  >{intl.formatMessage({ id: 'deactivatePod' })}</button>  ) : ( <button className="btn btn-warning" onClick={() => { activatePod(getpoddetails.id) }}>Activate Pod</button> )}
              
             

<ManageButton1  onClick={()=>{deletepod(getpoddetails.id)}}>{intl.formatMessage({ id: 'delete' })} </ManageButton1>

<Buttontask onClick={()=>{
                  setTask(true)
                  setTaskid(getpoddetails.id,getpoddetails.podName)
                  }} >{intl.formatMessage({ id: 'createTask' })}</Buttontask>

<Buttontasksetting onClick={()=>{
                  setSetting(true)
                  setSettingid(getpoddetails.id,getpoddetails.podName)
                  }} >{intl.formatMessage({ id: 'setting' })}</Buttontasksetting>
            </PodDiv>

           
         
       
           

<Candidatesdetails>
  <p>{intl.formatMessage({ id: 'PodTeamMember' })}</p>
  <br></br>
  <div>
{getcandidates.length > 0 ?
getcandidates.map((data)=><>
<NavHashLink to={{
                pathname: '/podcandidatereview',
                state: {
                    
                    cid: data.c_id
                }
            }}
            smooth style={{color:'blue'}}>

<PositionCand1>{data.firstName} <span style={{paddingLeft:'2px'}}>{data.lastName}</span></PositionCand1></NavHashLink>
 
{data.podID == getpoddetails.id &&<Title style={{ marging : "10px" }}>
{data.profilePicture=="" ? (
                <img
  src={defaultimg}
  alt=""
  style={{ width: 40, height: 40, borderRadius : "50px"  }}
></img>
)


  :(
    <img
    src={'https://globldata.s3.amazonaws.com/'+ data.profilePicture}
    alt=""
    style={{ width: 40, height: 40, borderRadius : "50px"  }}
  ></img>
) }
  <Colum>
  <PositionCand1>{data.firstName} <span style={{paddingLeft:'2px'}}>{data.lastName}</span></PositionCand1>
 
 
 {/* <PositionCand>{data.work_position}</PositionCand> */}
 </Colum>
</Title>}  
            
             </>
) : (intl.formatMessage({ id: 'noCandidatesAddedToPod' }))}

<Button2 onClick={()=>{
                  setModal(true)
               
                  }} >{intl.formatMessage({ id: 'invite' })}</Button2>
                  {/* <Button3 onClick={()=>{
                  setStakemodal(true)
               
                  }} >Invite Stakeholder</Button3> */}
</div>
</Candidatesdetails>

          
          </Poddetails>

          {stakemodal  && <>
            <Modal
            open={stakemodal}
            onClose={()=>{setStakemodal(false)}}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <Stakeholderinvite  />
              </Box>
            </Modal>
          </>}

          {modal  && <>
            <Modal
            open={modal}
            onClose={()=>{setModal(false)}}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <Popup  />
              </Box>
            </Modal>
          </>}




          {managepod && (managePodId == getpoddetails.id) && <>
            <Modal
            open={managepod}
            onClose={()=>{setManagePod(false)}}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            >
              <Box sx={style} >
                <ClientManagePod pod_id={getpoddetails.id} pod_name={getpoddetails.podName} />
              </Box>
            </Modal>
          </>}




          {task  && <>
            <Modal
  open={task}
  onClose={() => setTask(false)}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
  style={{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }}
>
  <div style={{
    backgroundColor: 'white',
    border: '2px solid #000',
    padding: '20px',
    maxHeight: '80%', // Set the maximum height of the modal
    overflowY: 'auto', // Enable vertical scrolling if content exceeds maxHeight
  }}>
              <Box >
                <TaskForm podid={getpoddetails.id} podname={getpoddetails.podName}  onClose={() => setTask(false)} />
              </Box>
              </div>
            </Modal>
          </>}
          {setting  && <>
            <Modal
            open={setting}
            onClose={()=>{setSetting(false)}}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            >
              <Box sx={style} >
                <Mastergithub podid={getpoddetails.id} name={getpoddetails.podName}  onClose={() => setSetting(false)}  />
              </Box>
            </Modal>
          </>}

          
          
           </CardContent></RecommendProject>
        {/* <li  >

            <div className='name'>   <img style={{ width: '50px', height: '50px' }} src={profile}></img> <p className='firstname'> <img  width="20px" height="20px" src={star}></img> <img  width="20px" height="20px" src={star}></img><img  width="20px" height="20px" src={star}></img> <img  width="20px" height="20px" src={star}></img></p> </div>

            <div className='name2'> <tr  className='TR'><td    className='TD'  >Name</td> <td>:</td><TD1 >{getpoddetails.podName} </TD1></tr></div>

            <div className='name2'> <tr className='TR' ><td className='TD'  >E-Mail</td> <td>:</td><TD1  > {getpoddetails.email}</TD1></tr></div>

            
            <div className='name2'> <tr  className='TR'><td className='TD'  >Clients</td> <td>:</td><TD1 > -- </TD1></tr></div>


            <div className='name2'> <tr  className='TR'><td className='TD' >Portfolio</td> <td>:</td><TD1>  {getpoddetails.portfolioUrl}</TD1></tr></div>

        
             
                 
                  
        
<div className='name2'> <tr  className='TR'><td className='TD' >Candidates</td> </tr></div>
{getcandidates.map((data)=>
   <div style={{display:"flex",flexDirection:"row", margin : "20px"}}><tr>
    <td style={{paddingRight:"10px",paddingTop:"10px"}}> <img
   src={Avatar}
   alt=""
   style={{ width: "40px", height: "40px" }}
 ></img></td></tr>
 <a href={`/podcandidatereview/${data.c_id}`} >
 <tr style={{display:'flex',flexDirection:'column', cursor : "pointer"}}> <TD2>  {data.firstName}  </TD2>  <TD3>{data.work_position}</TD3></tr>

 </a>
 </div>        

   )}


   

<br></br>



    </li> */}
<br></br>
    <Podreviewrating id={getpoddetails.id} /><br></br>


</Div>


<br></br>

</OL1>                     

    )

}
    
export default FindPodsDetails;

const OL1 = styled.ol`
list-style: none;
margin:auto;

margin-top: 10rem;
@media screen and (min-width:320px) and (max-width:1080px){
    display:flex;
flex-direction:column;
margin-top:10rem;
align-items:center;
justify-content:center;


}
`;

const Return = styled.ol`

margin:auto;

margin-top:5rem;
@media screen and (min-width:320px) and (max-width:1080px){
    margin:auto;
    margin-top:5rem;
    

}
`;

const TD1 =styled.td`
margin-left: 2rem;
width: 12rem;
overflow-wrap: anywhere;  font-weight: 600;
font-family: Poppins,medium;
@media screen and (min-width:320px) and (max-width:1080px){
 margin:auto;
 margin-left: 2rem;
    width: 8rem;
    overflow-wrap: anywhere;
}
`;
const TD2 =styled.td`

width: 12rem;
overflow-wrap: anywhere;
padding-top:10px;
font-weight: 600;
font-family: Poppins,medium;
@media screen and (min-width:320px) and (max-width:1080px){
 margin:auto;

    width: 8rem;
    overflow-wrap: anywhere;
}
`;

const TD3=styled.td`

width: 12rem;
overflow-wrap: anywhere;
font-weight: 600;
font-size:14px;
font-family: Poppins,medium;

@media screen and (min-width:320px) and (max-width:1080px){
 margin:auto;

    width: 8rem;
    overflow-wrap: anywhere;
}
`;



const Div = styled.div`
  // display:flex;
  display: inline;
  jutify-content: center;
  flex-direction: column;

  align-items: center;

  margin-left: 6.5rem;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    display: flex;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    margin: auto;
    width: 25rem;
  }
`;

const CreateButton=styled.button`
display:flex;
justify-content:center;
align-items:center;
padding:5px;
width:7.6rem;
height:2.3rem;
font-size:16px;
font-weight:600;
color:white;
border:none;
border-radius:5px  ;
background-color:#1DBF73;
box-shadow: 1px 3px 6px #00000029;
margin-top:0rem;


`;

const Div1 = styled.div`
  display: flex;

  jutify-content: center;
  flex-direction: row;

  align-items: center;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    display: flex;
    flex-direction: column;
  }
`;

const ButtonEdit = styled.button`

  text-decoration: none;
  border: none;
  margin-left: auto;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    display: flex;
    flex-direction: column;

    margin: auto;
    margin-top:2rem;
  }

`;
const RecommendProject = styled.div`

 display:flex;

jutify-content:center;
flex-direction:row;
@media screen and (min-width:320px) and (max-width:1080px){
    width:100%;
    height:max-content;
    margin:auto;
    
 display:flex;


 flex-direction:column;
    
   }
`;

const Row = styled.div`
  display: flex;

  align-item: center;
  margin-bottom: 0.8rem;
 
`;
const TitleText = styled.h4`
  font-weight: 600;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
   margin-top:2rem;
  }

`;

const Position = styled.p`

width:8rem;
font-size:15px;
  font-weight: 600;
  overflow-wrap: anywhere;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    width:4rem;
  }
`;


const Position1 = styled.p`
  font-weight: 600;
  width:9rem;
  font-size:15px;

  overflow-wrap: anywhere;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    width:4rem;
  }
`;



const Answer = styled.p`
  font-weight: 600;
  width:15rem;
  font-size:15px;
  overflow-wrap: anywhere;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    width:7rem;
    overflow-wrap: anywhere;
  }
`;


const Answer1 = styled.p`
  font-weight: 600;
  width:16rem;
  font-size:15px;
  overflow-wrap: anywhere;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    width:7rem;
    overflow-wrap: anywhere;
  }
`;


const CardContent = styled.div`
  margin: 0.4rem;
  width: 45rem;
  padding:1rem;
  
  height:max-height;

  border-radius:15px;
  font-family:Poppins,Medium;
  background-color:#F2F4F5;

  @media screen and (min-width: 320px) and (max-width: 1080px) {
    width: 20rem;
    margin:auto;
  }
`;
const Divseperator = styled.div`
// border-bottom:1px solid #CECECE;
padding:1rem;
`;
const Detail = styled.div`
  display: flex;
  justify-content: space-around;
  aligh-items: center;

  @media screen and (min-width: 320px) and (max-width: 1080px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 0.2rem;
    margin-bottom: 1rem;
  }
`;
const InfoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

`;

const Info = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-left: 15px;
`;
const InfoPod = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  margin-left: 15px;
`;


const InfoName = styled.h6`
  font-weight: 600;
`;
const InfoUpdate = styled.span`
  font-size: 13px;
  font-weight: 600;
  margin-right: auto;
`;
const PriceContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 600;
  padding: 0.3rem;

  border-radius: 7px;
  height: 100%;
  width: max-width;
  background-color: ${themeColor};

  @media screen and (min-width: 320px) and (max-width: 1080px) {
    flex-direction: column;
    // gap:0.4rem;
    width: 60%;
    height: 100%;
    margin-right: auto;
  }
`;
const Price = styled.div`
  color: white;
  width: max-width;
  padding-right: 10px;
`;
const Title = styled.h6`

  font-weight: 600;
  display:flex;
  flex-direction:row;
`;
const ProjectInfo = styled.h6`
font-weight: 600;
  display:flex;
  flex-direction:row;
`;

const SideCard = styled.div`
margin-left:5rem;
display:flex;
justify-content:center;
align-items:center;
flex-direction:column;
border-radius:20px;
font-family:Poppins,Medium;
width:20rem;
height:34rem;

padding: 1rem;
background-color: #F2F4F5;

box-shadow:${cardShadow};
transition:0.45 ease-in-out;
&:hover {
box-shadow:${hoverEffect};

}
@media screen and (min-width: 320px) and (max-width: 1080px) {
  width:20rem;

  margin: auto;
  margin-top:2rem;
}
`;


const SideCardContent = styled.div`
background-color:#90E0EF;
width:17rem;

display:flex;
flex-direction:column;
padding:3px;
margin:1rem;

border-radius:20px;
`;


const SideCardContent1 = styled.div`
background-color:#90E0EF;
width:17rem;
display:flex;
margin-top:1rem;
flex-direction:column;
height:10rem;
justify-content:center;
align-items:center;
border-radius:20px;
`;





const Detail1 = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;

  @media screen and (min-width: 320px) and (max-width: 1080px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 0.2rem;
    margin-bottom: 1rem;
  }
`;
const InfoContainer1 = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Info1 = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-left: 15px;
`;
const Row1 = styled.div`
  display: flex;

  align-item: center;
  margin-bottom: 0.8rem;
`;
const InfoName1 = styled.h6`
  font-weight: 600;
`;
const InfoUpdate1 = styled.span`
  font-size: 13px;
  font-weight: 600;
  margin-right: auto;
`;

const Title1 = styled.h6`
  font-weight: 600;
`;
const ProjectInfo1 = styled.p`
padding:5px;
margin:3px;
background-color:white;
border-radius:5px  ;
font-size:13px;
color:black;
font-weight:600;
margin-bottom:1rem
`;

const ViewAll = styled.button`
width:5rem;
font-weight:600;
height:2rem;
margin-left:auto;
margin-top:1rem;
border: 2px solid #1DBF73;
border-radius:5px  ;
box-shadow: 1px 3px 6px #00000029;
`;

const View = styled.button`
width:8rem;
font-weight:600;
height:2rem;
background-color:white;
border-radius:5px  ;
margin-bottom:1rem;
margin-top:1rem;
border: none;
border-radius:5px  ;
box-shadow: 1px 3px 6px #00000029;
`;


const Poddetails = styled.div`
display:flex;
flex-direction:row;
@media screen and (min-width: 320px) and (max-width: 1080px) {
  flex-direction: column;
  justify-content: flex-start;
 
}
`;



const PodDiv  = styled.div`

`;

const Candidatesdetails  = styled.div`
height:12rem;
padding:5px;
width:14rem;
border-radius:15px;
height:20rem;
background-color:rgb(166, 194, 206,30%);
overflow-y: hidden;
overflow-x: hidden;
margin-top:2rem;
@media screen and (min-width: 320px) and (max-width: 1080px) {
    width: 18rem;

  }
`;

const Colum  = styled.div`

margin-left:10px;
`;


const PositionCand = styled.p`
  font-weight: 600;
 width:max-content;
 width:11rem;
  font-size:15px;
margin-top:-1rem;
  overflow-wrap: anywhere;
  @media screen and (min-width: 320px) and (max-width: 1080px) {

  }

  `;

  const PositionCand1 = styled.p`
  font-weight: 600;
 width:11rem;
  font-size:15px;

  overflow-wrap: anywhere;
  @media screen and (min-width: 320px) and (max-width: 1080px) {

  }

  `;

  const H1 = styled.h1`
font-size:22px;
font-weight:600;
margin-bottom:1.2rem;
`;


const Button=styled.button`
margin-top:1rem;
border:none;
border-radius: 10px;
background-color:#1DBF73;
box-shadow: 1px 3px 6px #00000029;
width:12rem;
height:2rem;
font-size:15px;
font-weight:600;
color:white;

`;


 
const Buttontasksetting=styled.button`
display:flex;
justify-content:center;
align-items:center;
margin-bottom:1rem;
padding:5px;
width:7.6rem;
height:2.3rem;
font-size:16px;
font-weight:600;
color:white;
border:none;
border-radius:5px  ;
background-color:#F39C12;
margin-top:1rem;
box-shadow: 1px 3px 6px #00000029;
@media screen and (min-width: 320px) and (max-width: 1080px) {
  margin-left:5rem;
  width:5rem;
  height:3rem;
  font-size:12px;
 }
`;
const Buttontask=styled.button`
display:flex;
justify-content:center;
align-items:center;
margin-bottom:1rem;
padding:5px;
width:7.6rem;
height:2.3rem;
font-size:16px;
font-weight:600;
color:white;
border:none;
border-radius:5px  ;
background-color:DodgerBlue;
margin-top:1rem;
box-shadow: 1px 3px 6px #00000029;
@media screen and (min-width: 320px) and (max-width: 1080px) {
  margin-left:5rem;
  width:5rem;
  height:3rem;
  font-size:12px;
 }
`;



const ManageButton=styled.button`
display:flex;
justify-content:center;
align-items:center;
margin-bottom:1rem;
padding:5px;
width:7.6rem;
height:2.3rem;
font-size:16px;
font-weight:600;
color:white;
border:none;
border-radius:5px  ;
background-color:#1DBF73;
box-shadow: 1px 3px 6px #00000029;
@media screen and (min-width: 320px) and (max-width: 1080px) {
  margin-left:5rem;
  width:5rem;
  height:3rem;
  font-size:12px;
 }



`;

const ManageButton1=styled.button`
display:flex;
justify-content:center;
align-items:center;
margin-top:1rem;
padding:5px;
width:7.6rem;
height:2.3rem;
font-size:16px;
font-weight:600;
color:white;
border:none;
border-radius:5px  ;
background-color:#00B4D8;
box-shadow: 1px 3px 6px #00000029;
@media screen and (min-width: 320px) and (max-width: 1080px) {
  margin-left:5rem;
  width:5rem;
  height:3rem;
  font-size:12px;
 }



`;


const Button2 = styled.button`
font-family:Poppins,Medium;
    background-color: #00B4D8;
    color: white;
   font-size: 15px;
     border-radius: 50px;
     height: 30px;
        width:5rem;
        margin-left:2rem;
border:none;
    font-weight: 600;
    font-family: Poppins,Medium;
    box-shadow: 1px 3px 6px #00000029;
    @media screen and (min-width:320px) and (max-width:1080px){
        width:10rem;
        height:max-height;
        margin:auto;
        }
`;

const Button3= styled.button`
font-family:Poppins,Medium;
    background-color: #00B4D8;
    color: white;
   font-size: 15px;
     border-radius: 50px;
     height: 30px;
        width:10rem;
        margin-left:2rem;
        margin-top:10px;
border:none;
    font-weight: 600;
    font-family: Poppins,Medium;
    box-shadow: 1px 3px 6px #00000029;
    @media screen and (min-width:320px) and (max-width:1080px){
        width:10rem;
        height:max-height;
        margin:auto;
        }
`;