import React ,{Component, useState, useEffect}from "react";
import '../components/css/addcandidate.css';
import axios from 'axios';
// import FormikControl from './formikcontrol';
import Validate from "./utility/FormValidation";
import FormErrors from "./FormErrors";
// import select from "react-select/src/select";
import { Link, useHistory, useParams } from "react-router-dom";
import Navbar from "../Dashboard/Navbar";
import PersonIcon from '@mui/icons-material/Person';
import { useDispatch, useSelector } from "react-redux";
import { login } from "./action/auth";
import EditIcon from '@mui/icons-material/Edit';
import Footer from "./Footer";
import { updateProfilecandidate } from "./action/auth";
import Sidebar from "./Sidebar";
import star from './assets/star.png';
import logo from './assets/programmer.png';
import { Circles } from 'react-loader-spinner'

import AWS from 'aws-sdk';

import S3FileUpload from 'react-s3';

import { Buffer } from "buffer";
import { useAuthToken } from "../TokenContext";
Buffer.from("anything", "base64");
window.Buffer = window.Buffer || require("buffer").Buffer;

const config = {
  bucketName: process.env.REACT_APP_BUCKETNAME,
  region: process.env.REACT_APP_REGION,
  accessKeyId: process.env.REACT_APP_ACCESSKEYID,
  secretAccessKey: process.env.REACT_APP_SECRETACCESSKEY
}

const S3Config = {
  region: process.env.REACT_APP_REGION,
  accessKeyId: process.env.REACT_APP_ACCESSKEYID,
  secretAccessKey: process.env.REACT_APP_SECRETACCESSKEY
}
const area = [
   { 
     value: 'Node', label: 'Node'
    },
  { 
    value: 'Java', label: 'Java'
   },
  { 
    value: 'React', label: 'React'
   },
  { 
    value: 'Python', label: 'Python' 
  },
  { 
    value: 'AWS', label: 'AWS' 
  },
  { 
    value: 'C++', label: 'C++' 
  }
]

//for country
const country = [
  { 
    value: 'USA', label: 'USA'
   },
 { 
   value: 'Canada', label: 'Canada'
  },
 { 
   value: 'India', label: 'India'
  }
]
//new
const industry = [
  { 
    value: ' Healthcare', label: ' Healthcare'
   },
 { 
   value: ' Banking and Financial', label: ' Banking and Financial'
  },
 { 
   value: 'Services', label: 'Services'
  }
]



const Clientadminprofile = () => {
  const token = useAuthToken();


  const [Edit, setEdit] = useState(false); 
  const [Image, setImage] = useState(""); 
  const [AuthImage, setAuthImage] = useState(""); 

  let  { id } = useParams();


  const [Uploading, setUploading] = useState(false)
  const [S3Object, setS3Object] = useState([])
  const [Resume, setResume] = useState(""); 



  const { isLoggedIn, user } = useSelector(state => state.auth);



  const dispatch = useDispatch();

   const [arrexp, setArrexp] = useState({
    experience : [],
    education : [],
    certification: []
  });

  const handleChangeExp = (e,idx) => {
    let clone = [...arrexp.experience];
    let obj = clone[idx];
    obj[e.target.id] = e.target.value;
    clone[idx] = obj;
    setArrexp({ experience : [...clone]})
   }
   const handleChange = (e,idx) => {
    let clone = [...arrexp.education];
    let obj = clone[idx];
    obj[e.target.id] = e.target.value;
    clone[idx] = obj;
    setArrexp({ education: [...clone]})
   }


  const history = useHistory();

  const myBucket = new AWS.S3(S3Config);

  const [State, setState] = useState({})


  function generatePreSignedPutUrl11(picture) {
   
    const URL_EXPIRATION_TIME = 900;
  
                  const params = {
                    Bucket: process.env.REACT_APP_BUCKETNAME,
                    Key: picture,
                    Expires: URL_EXPIRATION_TIME,
                  }
                 
               myBucket.getSignedUrl('getObject', params, (err, url) => {

                console.log(err, url)
  
                setAuthImage(url);
  
              });
  }

  

  

 const approved = async () =>  {
  const headers = {
    'Authorization' : token, // Replace with your actual token
    'Content-Type': 'application/json', // You can add more headers as needed
  };
  try {
    

    // https://stk7ftigpk.execute-api.us-east-1.amazonaws.com/dev/approveuser
       const Approved = await axios.post(process.env.REACT_APP_TALENTADMIN_APPROVEUSER, { table: "employer", id : State.emp_id },{headers:headers});
  
  
    
  if(Approved.data){

    var params = {
      Source: 'ppod@digitivy.com',  /* required */
      Destination: { /* required */
        ToAddresses: [
          'ppod@digitivy.com'
          /* more items */
        ]
      },
      ReplyToAddresses: [
        State.email,
       /* more items */
     ],
      Message: { /* required */
        Body: { /* required */
          Html: {
           Charset: "UTF-8",
           Data: "<p>Hi, " + State.firstName + "</p><br></br> <br></br>Congrats! Your Client Profile is Approved in PrismPod. <br></br> <br></br> <b>You Can login to <a href='www.prismpod.com'>PrismPOD.com</a> to access your profile now.</b> <br></br><br></br>  <p>Thanks.</p>"
          },
          Text: {
           Charset: "UTF-8",
           Data: "Hi, Your Client Profile is Approved in PrismPod"
          }
         },
         Subject: {
          Charset: 'UTF-8',
          Data: "Congrats!, Your Client Profile is Approved in PrismPod."
        }
        },
     
    };
    
    // Create the promise and SES service object
    var sendPromise = new AWS.SES({
      region: 'us-east-1',
      accessKeyId: S3Config.accessKeyId,
      secretAccessKey: S3Config.secretAccessKey
       
     }).sendEmail(params).promise();
    
    // Handle promise's fulfilled/rejected states
    sendPromise.then(
      
      function(data) {
      alert("These Client Profile is Approved!")
      // window.location.href = '/clientadmin-dashboard'
      }).catch(
        function(err) {
          alert("These Client Profile is Approved!")
          // window.location.href = '/clientadmin-dashboard'
        console.error(err);
      });


  }
  
  } catch (error) {
    
    console.log(error);
  
  }

}


const rejectclient = () => {


  let person = prompt("Please Enter Reason of Rejection");
  const headers = {
    'Authorization' : token, // Replace with your actual token
    'Content-Type': 'application/json', // You can add more headers as needed
  };


  if(person != null) {
   
    if(person == ""){
      alert("Please Enter The Reason.");
    }
    else{
      // https://yyfyjhh84b.execute-api.us-east-1.amazonaws.com/dev/rejectuser
    axios.post(process.env.REACT_APP_TALENTADMIN_REJECTUSER, { table: "employer", id : State.emp_id },{headers:headers}).then((data) => {
      
 
    var params = {
      Source: 'ppod@digitivy.com',  /* required */
      Destination: { /* required */
        ToAddresses: [
          'ppod@digitivy.com'
          /* more items */
        ]
      },
      ReplyToAddresses: [
        State.email,
       /* more items */
     ],
      Message: { /* required */
        Body: { /* required */
          Html: {
           Charset: "UTF-8",
           Data: "<p>Hi, " + State.emp_firstname + "</p><br></br> <br></br> Your Client Profile is Rejected in PrismPod. <br></br> <br></br><b> Reason of Rejection : </b> " + person + "<br></br> <br></br> <b>Pls login to <a href='www.prismpod.com'>PrismPOD.com</a> to update your profile.</b> <br></br> <p>Thanks.</p>"
          },
          Text: {
           Charset: "UTF-8",
           Data: "Hi, Your Client Profile is Rejected in PrismPod, Please Update Your Profile."
          }
         },
         Subject: {
          Charset: 'UTF-8',
          Data: "Your Client Profile is Rejected in PrismPod"
        }
        },
     
    };
    
    // Create the promise and SES service object
    var sendPromise = new AWS.SES({
      region: 'us-east-1',
      accessKeyId: S3Config.accessKeyId,
      secretAccessKey: S3Config.secretAccessKey
       
     }).sendEmail(params).promise();
    
    // Handle promise's fulfilled/rejected states
    sendPromise.then(
      function(data) {
       
        alert("These Client Profile is Rejected!")
      }).catch(
        function(err) {
        console.error(err);
      });


    }).catch((err) => {
      console.log(err)
       })

      }
 
      }


}    

  useEffect( () => {
 
    user.data[0].profile == 0 ? setEdit(true) : setEdit(false); 

    const headers = {
      'Authorization' : token, // Replace with your actual token
      'Content-Type': 'application/json', // You can add more headers as needed
    };
  
    // https://7jyac66zu9.execute-api.us-east-1.amazonaws.com/dev/getCandidateByid
         axios.post(process.env.REACT_APP_TALENTLEAD_GETCANDIDATEBYID, { id : id, table : "employer" },{headers:headers}).then((result) => {

          setState(result.data.data[0]);
          //console.log('hello',result.data.data[0]);
   
          // generatePreSignedPutUrl11(result.data.data[0].profilePicture);

         }).catch((err) => {
          console.log(err);
         })



  }, [token])


  

  const { profileImg} = State
    return (

     <div className="container" style={{ "margin-top": "140px" }}>
{State.email!=undefined ?
 

 
      <div className="row" >


<br></br>
        <div className="col-lg-2" ></div>

 

        <div className="col-lg-6" style={{ boxShadow: "1px 3px 6px #00000029", border: "1px solid #D8D8D8", borderRadius: "16px", opacity: 1, padding: "60px"  }}>
      


<center>
          
 <img  src={'https://globldata.s3.amazonaws.com/'+ State.profilePicture}  style={{width:'100px',height:'100px',marginTop:"50px", borderRadius : "50px"}}></img> 

 
            <br></br>
            <br></br>


            <p style={{ marginLeft : "-1px", color :  "black", width: "100%", fontSize: "18px", fontWeight: "bold", textAlign: "center" }} >{State.emp_firstname + " " + State.emp_lastname}</p>
            <p style={{ marginLeft : "-55px", color :  "grey", width: "170px", fontSize: "18px" }} >{State.email}</p>
            <p style={{ marginLeft : "-15px", color :  "grey", width: "100%", fontSize: "16px" }} >{State.emp_country}  </p>
         



            </center>
            <br></br>
<div style={{ border: "1px solid #E7E7E7", borderRadius: "5px", padding: "40px", height: "600px", overflow: "scroll"}}>

<p style={{ marginLeft : "-4px", color :  "#8EAEBC", fontWeight: "bold" , fontSize: "18px", width: "170px",float: "left" }} >Basic information</p>
<p style={{ marginLeft : "-55px", color :  "grey", width: "170px", fontSize: "18px" }} >About</p>

<p style={{ color :  "black", maxWidth: "100%", height: "auto", fontWeight: "bold", fontSize: "18px", float: "left",marginLeft: "5px"  }} >{State.about}</p>
<br></br>
<br></br>
<p style={{ marginLeft : "-55px", color :  "grey", width: "170px", fontSize: "18px" }} >Phone</p>


<p style={{ color :  "black", maxWidth: "100%", height: "auto", fontWeight: "bold", fontSize: "18px", float: "left",marginLeft: "5px"  }} >{State.phone}</p>

{/* <br></br>
<p style={{ marginLeft : "-20px", color :  "grey", width: "170px", fontSize: "18px" }} >Company Name</p>


<p style={{ color :  "black", maxWidth: "100%", height: "auto", fontWeight: "bold", fontSize: "18px", float: "left",marginLeft: "5px"  }} >{State.companyName}</p>
<br></br> */}


</div>




{State.isApproved == "Rejected" || State.isApproved == "Unapproved"  ? (<button className="button is-success" onClick={approved} style={{ width : "40%", marginRight: "20px"}} type="submit" >Approve Candidate</button>) : (<></>)}
{State.isApproved == "Approved" || State.isApproved == "Unapproved"  ? (<button className="button is-danger" onClick={rejectclient} style={{ width : "40%"}} type="submit" >Reject Candidate</button>) : (<></>)}



</div>


        <div className="col-lg-2"></div>
        <div className="col-lg-1"></div>


        </div>

: 
<div style={{display: 'flex',justifyContent: 'center',alignItems: 'center'}}>
<Circles
height="50"
width="50"
color="black"
ariaLabel="circles-loading"

wrapperStyle={{}}
wrapperClass=""
visible={true}
/> </div> }
        </div>
 


    );
  }

export default Clientadminprofile;

