import React ,{Component, useState, useEffect}from "react";
import '../components/css/addcandidate.css';
import axios from 'axios';
// import FormikControl from './formikcontrol';
import Validate from "./utility/FormValidation";
import FormErrors from "./FormErrors";
// import select from "react-select/src/select";
import { Link, useHistory, useParams } from "react-router-dom";
import Navbar from "../Dashboard/Navbar";
import PersonIcon from '@mui/icons-material/Person';
import { useDispatch, useSelector } from "react-redux";
import { login } from "./action/auth";
import EditIcon from '@mui/icons-material/Edit';
import Footer from "./Footer";
import { updateProfilecandidate } from "./action/auth";
import Sidebar from "./Sidebar";
import star from './assets/star.png';
import defaultimg from './assets/defaultimge.png';
import AWS from 'aws-sdk';
import { Circles } from 'react-loader-spinner'
import S3FileUpload from 'react-s3';

import { Buffer } from "buffer";
import RatingandReview from "./RatingandReview";
import { useLocation } from 'react-router-dom';
import { useAuthToken } from "../TokenContext";
Buffer.from("anything", "base64");
window.Buffer = window.Buffer || require("buffer").Buffer;


const config = {
  bucketName: process.env.REACT_APP_BUCKETNAME,
  region: process.env.REACT_APP_REGION,
  accessKeyId: process.env.REACT_APP_ACCESSKEYID,
  secretAccessKey: process.env.REACT_APP_SECRETACCESSKEY
}

const S3Config = {
  region: process.env.REACT_APP_REGION,
  accessKeyId: process.env.REACT_APP_ACCESSKEYID,
  secretAccessKey: process.env.REACT_APP_SECRETACCESSKEY
}


const area = [
   { 
     value: 'Node', label: 'Node'
    },
  { 
    value: 'Java', label: 'Java'
   },
  { 
    value: 'React', label: 'React'
   },
  { 
    value: 'Python', label: 'Python' 
  },
  { 
    value: 'AWS', label: 'AWS' 
  },
  { 
    value: 'C++', label: 'C++' 
  }
]

//for country
const country = [
  { 
    value: 'USA', label: 'USA'
   },
 { 
   value: 'Canada', label: 'Canada'
  },
 { 
   value: 'India', label: 'India'
  }
]
//new
const industry = [
  { 
    value: ' Healthcare', label: ' Healthcare'
   },
 { 
   value: ' Banking and Financial', label: ' Banking and Financial'
  },
 { 
   value: 'Services', label: 'Services'
  }
]



const Podcandidatereview = () => {

  const location = useLocation();
  const { cid } = location.state

  const [Edit, setEdit] = useState(false); 
  const [Image, setImage] = useState(""); 
  const [AuthImage, setAuthImage] = useState(""); 

  let  { id } = useParams();
  const token = useAuthToken();



  const [Uploading, setUploading] = useState(false)
  const [S3Object, setS3Object] = useState([])
  const [Resume, setResume] = useState(""); 



  const { isLoggedIn, user } = useSelector(state => state.auth);

  console.log(id)

  const dispatch = useDispatch();

   const [arrexp, setArrexp] = useState({
    experience : [],
    education : [],
    certification: [],
    podExperience :[]
  });

  const handleChangeExp = (e,idx) => {
    let clone = [...arrexp.experience];
    let obj = clone[idx];
    obj[e.target.id] = e.target.value;
    clone[idx] = obj;
    setArrexp({ experience : [...clone]})
   }
   const handleChange = (e,idx) => {
    let clone = [...arrexp.education];
    let obj = clone[idx];
    obj[e.target.id] = e.target.value;
    clone[idx] = obj;
    setArrexp({ education: [...clone]})
   }


  const history = useHistory();

  const myBucket = new AWS.S3(S3Config);

  const [State, setState] = useState({})


  function generatePreSignedPutUrl11() {
   
    const URL_EXPIRATION_TIME = 900;
  
                  const params = {
                    Bucket:process.env.REACT_APP_BUCKETNAME,
                    Key: State.profilePicture,
                    Expires: URL_EXPIRATION_TIME,
                  }
                 
               myBucket.getSignedUrl('getObject', params, (err, url) => {

                
                setAuthImage(url);
  
              });
  }

  
  function generatePreSignedPutUrl11Resume() {
   
    const URL_EXPIRATION_TIME = 900;
  
                  const params = {
                    Bucket: process.env.REACT_APP_BUCKETNAME,
                    Key: State.resume,
                    Expires: URL_EXPIRATION_TIME,
                  }
                 
               myBucket.getSignedUrl('getObject', params, (err, url) => {

                
                setResume(url);
  
              });
  }
  

  useEffect( async () => {
    // if (arrexp.podExperience && arrexp.podExperience.length > 0) {
    //   alert("Welcome to the page with experience!");
    // } else {
    //   alert("This Talent Doesn't have any Pod Experience!");
    // }


    const headers = {
      'Authorization' : token, // Replace with your actual token
      'Content-Type': 'application/json', // You can add more headers as needed
    };
    user.data[0].profile == 0 ? setEdit(true) : setEdit(false); 

    generatePreSignedPutUrl11();
    generatePreSignedPutUrl11Resume()

    try{
  
      
      // https://583amcrdw0.execute-api.us-east-1.amazonaws.com/dev/getcandidatebyid
      const addeddata = await axios.post(process.env.REACT_APP_CLIENT_GETCANDIDATEBYID, { id : cid, table : "candidate" },{headers:headers});


      
      
      // https://svty7iwcsb.execute-api.us-east-1.amazonaws.com/dev/getcandidatedata
      const added = await axios.post(process.env.REACT_APP_CLIENT_GETCANDIDATEDATA, { id : cid },{headers:headers});


      
  
      setArrexp({
        experience : added.data.data.exp,
        education : added.data.data.education,
        certification : added.data.data.certification,
        podExperience :added.data.data.podExperience 
      })
   
  
      setState(addeddata.data.data[0]);

      const URL_EXPIRATION_TIME = 900;
  
      const params = {
        Bucket: process.env.REACT_APP_BUCKETNAME,
        Key: addeddata.data.data[0].profilePicture,
        Expires: URL_EXPIRATION_TIME,
      }
     
   myBucket.getSignedUrl('getObject', params, (err, url) => {

    
    setAuthImage(url);

  });

   

    

    }catch(err){
      console.log(err)
    }

  }, [token])


 

  const { profileImg} = State
    return (

     <div className="container" style={{ "margin-top": "140px" }}>
{State.email!=undefined ?

      <div className="row" >
<br></br>
        <div className="col-lg-3" ></div>
 
        <div className="col-lg-6" style={{ boxShadow: "1px 3px 6px #00000029", border: "1px solid #D8D8D8", borderRadius: "16px", opacity: 1, padding: "60px"  }}>



<center>
{State.profilePicture == "" || State.profilePicture == null ? (
            <img
              src={defaultimg}
              alt=""
              style={{ width: 50, height: 50, borderRadius: "50px" }}
            ></img>
          ) : (
            <img
              style={{ width: '50px', height: '50px', borderRadius: "50px" }}
              src={'https://globldata.s3.amazonaws.com/' + State.profilePicture}
            ></img>
          )}


 
            <br></br>
            <br></br>
            <p style={{ marginLeft : "-1px", color :  "black", width: "100%", fontSize: "18px", fontWeight: "bold", textAlign: "center" }} >{State.firstName + " " + State.lastName}</p>
            <p style={{ marginLeft : "-55px", color :  "grey", width: "170px", fontSize: "18px" }} >{State.email}</p>
            <p style={{ marginLeft : "-15px", color :  "grey", width: "100%", fontSize: "16px" }} >{State.country}  
 </p>


            </center>
            <br></br>
<div style={{ border: "1px solid #E7E7E7", borderRadius: "5px", padding: "40px", height: "300px", overflowWrap: "break-word"}}>
<p style={{ marginLeft: "5px", color :  "#8EAEBC", fontWeight: "bold" , fontSize: "18px", float: "left", maxWidth: "100%", }} >Basic information</p>
<p style={{ marginLeft : "-55px", color :  "grey", width: "170px", fontSize: "16px" }} >About</p>

<p style={{ color :  "black", maxWidth: "100%", height: "auto", fontWeight: "bold", fontSize: "16px", float: "left",marginLeft: "5px" }} >{State.about}</p>

<br></br>
</div>
<br></br>
<br></br>
<div style={{ border: "1px solid #E7E7E7", borderRadius: "5px", padding: "40px", height: "400px", overflowWrap: "break-word"}}>

            <p style={{ marginRight: "325px", color :  "#8EAEBC", fontWeight: "bold" , fontSize: "18px", width: "160px", float: "left" }} >Skills & Expertise</p>

            {/* For Primary Expertise */}
            <p style={{ marginLeft : "-55px", color :  "grey", width: "170px", fontSize: "16px" }} >Skills</p>

<p style={{ color :  "black", maxWidth: "100%", height: "auto", fontWeight: "bold", fontSize: "16px" }} >{State.primaryskill}, {State.secondaryskill} </p>
<br></br>
<br></br>
<p style={{ marginLeft : "-45px", color :  "grey", width: "170px", fontSize: "16px" }} >Industry</p>
<p style={{ color :  "black", maxWidth: "100%", height: "auto", fontWeight: "bold", fontSize: "16px" }} >{State.industry}</p>

</div>


<br></br>
<div style={{ border: "1px solid #E7E7E7", borderRadius: "5px", padding: "40px", height: "400px", overflow : "scroll"}}>

<br></br>
            <p style={{ marginRight: "325px", color :  "#8EAEBC", fontWeight: "bold" , fontSize: "18px", width: "229px" }}>Education & Certifications</p>

            <p style={{ marginLeft : "-44px", color :  "black", width: "170px", fontSize: "16px" }} >Education</p>

{  arrexp.education.map((data) => {
              return <div style={{ margin : "40px" }}> <p style={{ color :  "black", maxWidth: "100%", height: "auto", fontSize: "16px" }} >{data.degree}</p>
<p style={{ color :  "black", maxWidth: "100%", height: "auto", fontSize: "16px" }} >{data.institution}</p>
<p style={{ color :  "black", maxWidth: "100%", height: "auto", fontSize: "16px" }}  >{data.startDate}</p>
</div>
})}
</div>


<br></br>
<div style={{ border: "1px solid #E7E7E7", borderRadius: "5px", padding: "40px", overflow : "scroll" }}>

<p style={{ marginLeft : "-30px", color :  "#8EAEBC", width: "170px", fontSize: "18px" , fontWeight: "bold"}} >Certification</p>

{  arrexp.certification.map((data) => {
              return <div style={{ margin : "40px" }}> 

<p style={{ color :  "black", maxWidth: "100%", height: "auto", fontSize: "16px" }}  >{data.certification}</p>
<p style={{ color :  "black", maxWidth: "100%", height: "auto", fontSize: "16px" }}  >{data.certificationIntitution}</p>
</div>

})}

<br></br>
</div>
<br></br>
<div style={{ border: "1px solid #E7E7E7", borderRadius: "5px", padding: "40px", overflow : "scroll" }}>

<br></br>
<p style={{ marginLeft : "-41px", color :  "#8EAEBC", fontWeight: "bold" , fontSize: "18px", width: "229px" }}>Work Experience</p>
{ arrexp.experience.map((data) => {
       return <div style={{ margin : "40px" }}> <p style={{ color :  "black", maxWidth: "100%", height: "auto", fontSize: "16px" }}  >{data.work_position}</p>
<p style={{ color :  "black", maxWidth: "100%", height: "auto", fontSize: "16px" }}  >{data.work_organization}</p>
<p style={{ color :  "black", maxWidth: "100%", height: "auto", fontSize: "16px" }}  >{data.workStartdate}</p></div>
})}


<br></br>
</div>
<div style={{ border: "1px solid #E7E7E7", borderRadius: "5px", padding: "40px", overflow : "scroll" }}>

<br></br>
<p style={{ marginLeft : "-41px", color :  "#8EAEBC", fontWeight: "bold" , fontSize: "18px", width: "229px" }}>Pod Experience</p>{arrexp.podExperience>0? arrexp.podExperience.map((data) => {
       return <div style={{ margin : "40px" }}> <p style={{ color :  "black", maxWidth: "100%", height: "auto", fontSize: "16px" }}  >experince</p>
<p style={{ color :  "black", maxWidth: "100%", height: "auto", fontSize: "16px" }}  >experince</p>
</div>
}):("This Talent Doesn't have any Pod Experience!")}</div>
<br></br>
<div style={{ border: "1px solid #E7E7E7", borderRadius: "5px", padding: "40px", height: "950px", overflowWrap: "break-word"}}>

<p style={{ marginLeft : "-41px", color :  "#8EAEBC", fontWeight: "bold" , fontSize: "18px", width: "229px" }}>Other information</p>
<p style={{ marginLeft : "-50px", color :  "grey", width: "170px", fontSize: "16px" }} >Portfolio</p>

<p style={{ color :  "black", maxWidth: "100%", height: "auto",  fontSize: "16px" }}  >{State.portfolioUrl}</p>
<br></br>
<p style={{ marginLeft : "-56px", color :  "grey", width: "170px", fontSize: "16px" }} >Github</p>

<p style={{ color :  "black", maxWidth: "100%", height: "auto",  fontSize: "16px" }}  >{State.githubUrl}</p>
<br></br>
<p style={{ marginLeft : "-56px", color :  "grey", width: "170px", fontSize: "16px" }} >LinkedIn</p>

<p style={{ color :  "black", maxWidth: "100%", height: "auto",  fontSize: "16px" }}  >{State.linkedinUrl}</p>
<br></br>
<p style={{ marginLeft : "-10px", color :  "grey", width: "170px", fontSize: "16px" }} >Preferred Work Hours</p>


<p style={{ color :  "black", maxWidth: "100%", height: "auto",  fontSize: "16px" }}  >{State.workhours}</p>
<br></br>
<p style={{ marginLeft :"-56px", color :  "grey", width: "170px", fontSize: "16px"  }} >Rate /hr</p>

<p style={{ color :  "black", maxWidth: "100%", height: "auto", fontSize: "16px" }}  >{State.ratePerhour}</p>
<br></br>
<p style={{ marginLeft : "-43px", color :  "grey", width: "170px", fontSize: "16px"  }} >Rate /month</p>

<p style={{ color :  "black", maxWidth: "100%", height: "auto", fontSize: "16px" }}  >{State.ratePermonth}</p>
<br></br>
<p style={{ marginLeft :"-56px", color :  "grey", width: "170px", fontSize: "16px"  }} >Currency</p>

<p style={{ color :  "black", maxWidth: "100%", height: "auto", fontSize: "16px" }}  >{State.currency}</p>
<br></br>
<p style={{ marginLeft : "-60px", color :  "grey", width: "170px", fontSize: "16px"  }} >Resume</p>

<a href={Resume} style={{ color :  "black", maxWidth: "100%", height: "auto", fontSize: "16px", float: "left",marginLeft: "5px" }} >Resume Link</a>


<br></br>
<br></br>



</div>
<br></br>

<RatingandReview id={State.c_id} />  



</div>



        <div className="col-lg-3"></div>


        </div>

: 
<div style={{display: 'flex',justifyContent: 'center',alignItems: 'center'}}>
<Circles
height="50"
width="50"
color="black"
ariaLabel="circles-loading"

wrapperStyle={{}}
wrapperClass=""
visible={true}
/> </div> }
        </div>
 


    );
  }

export default Podcandidatereview;
