import React from 'react'
import profile_img from './assets/profile_img.png'
import { FiSearch } from 'react-icons/fi';
import styled from 'styled-components';
import { useState, useEffect } from 'react';
import { style } from '@mui/system';
import axios from 'axios';
import './CandidateAdminDashboard.css';
import logo from './assets/programmer.png';
import S3FileUpload from 'react-s3';
import AWS from 'aws-sdk';
import { useParams, useHistory } from 'react-router-dom';

import { useAuthToken } from "../TokenContext";


const S3Config = {
  region: process.env.REACT_APP_REGION,
  accessKeyId: process.env.REACT_APP_ACCESSKEYID,
  secretAccessKey: process.env.REACT_APP_SECRETACCESSKEY
}





const CandidateAdminDashboard = () => {
  const [currentPage, setcurrentPage] = useState(1);
  const [itemsPerPage, setitemsPerPage] = useState(6);
  const [Users, setUsers] = useState([]);
 
  const [pageNumberLimit, setpageNumberLimit] = useState(5);
  const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(5);
  const [minPageNumberLimit, setminPageNumberLimit] = useState(0);

  const params = useParams();
  const myBucket = new AWS.S3(S3Config);
  const [AuthImage, setAuthImage] = useState(''); 
  const token = useAuthToken();

  function generatePreSignedPutUrl11(profilePicture) {
   
    const URL_EXPIRATION_TIME = 900;
  
                  const params = {
                    Bucket: process.env.REACT_APP_BUCKETNAME,
                    Key: profilePicture,
                    Expires: URL_EXPIRATION_TIME,
                  }

                 
                 
               myBucket.getSignedUrl('getObject', params, (err, url) => {
  
                console.log(err, url)
                setAuthImage(url);
  
              });
  }


  useEffect(() => {

    getunApproved();


  }, [token])


  const getunApproved = async () => {
    const headers = {
      'Authorization' : token, // Replace with your actual token
      'Content-Type': 'application/json', // You can add more headers as needed
    };
    try {




      // https://nxgwiib0v1.execute-api.us-east-1.amazonaws.com/dev/getunapproved
      const getunApproved = await axios.post(process.env.REACT_APP_TALENTADMIN_GETUNAPPROVED, { table: "candidate",  type : "Unapproved" },{headers:headers});
      generatePreSignedPutUrl11( getunApproved.data.data.profilePicture)

    
      // console.log('heloooooo',getunApproved)
   
      setUsers(getunApproved.data.data);
   
    
     // console.log('trying',getunApproved.data.data[0].profilePicture);
     
    } catch (error) {

      console.log(error);

    }
  }

  const getApproved = async () => {
    const headers = {
      'Authorization' : token, // Replace with your actual token
      'Content-Type': 'application/json', // You can add more headers as needed
    };
    try {




      // https://nxgwiib0v1.execute-api.us-east-1.amazonaws.com/dev/getunapproved
      const getApproved = await axios.post(process.env.REACT_APP_TALENTADMIN_GETUNAPPROVED, { table: "candidate",  type : "Approved" },{headers:headers});

    //  console.log(getApproved)


      setUsers(getApproved.data.data);
     

    } catch (error) {

      console.log(error);

    }
  }

  const getRejected = async () => {
    const headers = {
      'Authorization' : token, // Replace with your actual token
      'Content-Type': 'application/json', // You can add more headers as needed
    };
    try {




      // https://nxgwiib0v1.execute-api.us-east-1.amazonaws.com/dev/getunapproved
      const getreject = await axios.post(process.env.REACT_APP_TALENTADMIN_GETUNAPPROVED, { table: "candidate",  type : "Rejected" },{headers:headers});

    ///  console.log(getreject);

      setUsers(getreject.data.data);
      

    } catch (error) {

      console.log(error);

    }
  }

  const handleClick = (event) => {
    setcurrentPage(Number(event.target.id));
  }


  const pages = [];
  for (let i = 1; i <= Math.ceil(Users.length / itemsPerPage); i++) {
    pages.push(i);
  }
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = Users.slice(indexOfFirstItem, indexOfLastItem);
  const renderPageNumbers = pages.map(number => {
    if (number < maxPageNumberLimit + 1 && number > minPageNumberLimit) {
      return (
        <li key={number} id={number} onClick={handleClick} className={currentPage == number ? 'active' : null}>{number}</li>
      );
    } else {
      return null;
    }
  });

  const handleNextbtn = () => {
    setcurrentPage(currentPage + 1);
    if (currentPage + 1 > maxPageNumberLimit) {
      setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit + pageNumberLimit);
    }
  };
  const handlePrevbtn = () => {
    setcurrentPage(currentPage - 1);
    if ((currentPage - 1) % pageNumberLimit == 0) {
      setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit - pageNumberLimit);
    }
  };


  const handleFilter = (event) => {

    if(event.target.value == "Approved"){
      getApproved();
    }else if(event.target.value == "Unapproved"){
         getunApproved();
    }else if(event.target.value == "Rejected"){
      getRejected();
    }

  }

  const renderdata = (Users) => {
    return (
      <div>
        <ol className='pageNumbers' >
          <Num2>
            <li>
              <button onClick={handlePrevbtn}
                disabled={currentPage == pages[0] ? true : false} >
                Prev
              </button>
            </li>
            <Numbers>{renderPageNumbers} </Numbers>
            <li>
              <button onClick={handleNextbtn}
                disabled={currentPage == pages[pages.length - 1] ? true : false}
              > Next</button>
            </li>
          </Num2>
        </ol>



        
         
         {

          Users && Users.length > 0 ? Users.map((data, i) => {

            if (data.type == "Candidate") {


              return <TableTag1>

              <TrTag1  >

                <TdTag4 >

                  <img 
               src={'https://globldata.s3.amazonaws.com/'+ data.profilePicture} 
                    alt=""
                  
                  style={{ width: '50px', height: '50px' }}  ></img>
                  <TdTag5 >
                    <PTag1 >{data.firstName + " " + data.lastName} </PTag1>
                    <PTag2 >{data.email}</PTag2>

                  </TdTag5>
                </TdTag4>

                <TdTag6>
                  <PTag3><a className="button is-success" href={`/candidate-profile/${data.c_id}`} >View Profile</a></PTag3>
                </TdTag6>
              </TrTag1>
              </TableTag1>
            }



          }) : <p style={{ textAlign : "center" }}>There are No Candidate. </p>

        }


      </div>


    )

  }

  return (
    <DivTag1 >

      <DivTag2  >
        <DivTag3 >
          <H5Tag>Candidate Admin Dashboard</H5Tag>
          <InputContainer>

            <Icon>
              <FiSearch />
            </Icon>
            <Input type="text" placeholder="Search for Cadidates" />
          </InputContainer>

          <td >
<select className='options' onChange={handleFilter}>
  <option >Status</option>
  <option value="Approved" >Approved</option>
  <option value="Unapproved" >Unapproved</option>
  <option value="Rejected" >Rejected</option>

  </select>
</td>

        </DivTag3>

        <TableTag >
          <TrTag1    >

            <TdTag1 >

              {/* <img src={profile_img} style={{width:'50px',height:'50px'}}  ></img> */}
              <TdTag2 >
                <PTag >List of Candidates</PTag>

              </TdTag2>
            </TdTag1>

            <TdTag3 ><PTag4> Approval Requests </PTag4></TdTag3>


          </TrTag1>
       

            

          

        </TableTag>

        {renderdata(currentItems)}
      </DivTag2>
    
    </DivTag1>

  )
}
const DivTag1 = styled.div`

        margin:auto;
        justify-content:center;
        `;
const DivTag2 = styled.div`

margin-top:8rem;
        width:1300px;
        overflow: auto;
        background-color:#e6e6ff;
        height:max-height;
        padding-bottom:1.5rem;
        border-radius:20px;
        border:1px solid #e6e6ff;
        @media screen and (min-width:320px) and (max-width:1080px){
          overflow: auto;
         margin:auto;
          width:20rem;
          margin-top:8rem;
}
        `;
const DivTag3 = styled.div`
        display:flex;
        flex-direction:row;
        padding-bottom:1.5rem;
        @media screen and (min-width:320px) and (max-width:1080px){
          display:flex;
        flex-direction:column;
}
        `;
const H5Tag = styled.h5`
        font-family:Poppins,Regular;
        font-weight:600;
        padding-top:1rem;
        margin-left:1.5rem;
        @media screen and (min-width:320px) and (max-width:1080px){
          margin - top:5rem;
}
        `;
const TableTag = styled.table`
        background-color:#f8f8f8;
        border:1px solid grey;
        text-decoration:none;
      width:1200px;
      overflow: auto;
        height:max-height;
        justify-content:center;
        align-items:center;
        margin:auto;
        @media screen and (min-width:320px) and (max-width:1080px){
          width:20rem;
          overflow: auto;
}
        `;

        const TableTag1 = styled.table`
        background-color:#f8f8f8;
        border:1px solid grey;
        text-decoration:none;
        width:1200px;
        overflow: auto;
        height:max-height;
        justify-content:center;
        align-items:center;
        margin:auto;
        @media screen and (min-width:320px) and (max-width:1080px){
          width:20rem;
          overflow: auto;
}
        `;

const TdTag1 = styled.td`
        display:flex;
        flex-direction:row;
        width:625px;
        overflow: auto;
        @media screen and (min-width:320px) and (max-width:1080px){
          width:10rem;
          overflow: auto;
}
        `;
const TrTag1 = styled.tr`

        border-bottom:1px solid grey;
        `;
const TdTag2 = styled.td`
        display:flex;
        flex-direction:column;
        padding-left:30px;
   

        `;
const TdTag3 = styled.td`

        
        background-color:#f8f8f8;
        align-items:center;
        width:625px;
        overflow: auto;
        font-family:Poppins,Regular;
        font-weight:600;
        padding-top:1rem;

        @media screen and (min-width:320px) and (max-width:1080px){
          width:10rem;
          overflow: auto;
}
        `;
const TdTag4 = styled.td`
        display:flex;
        flex-direction:row;
        padding-left:20px;
        padding-top:10px;
        `;
const TdTag5 = styled.td`
padding-left:1rem;
        display:flex;
        flex-direction:column;
        `;
const PTag = styled.p`
        font-family:Poppins,Regular;
        font-weight:600;
        padding-top:1rem;
        `;
const PTag1 = styled.p`
        font-family:Poppins,Regular;
        font-weight:600;
        `;
const PTag2 = styled.p`
        margin-top:-1rem;
        font-family:Poppins,Medium;
        font-weight:600;
        font-size:13px;
        `;
const TdTag6 = styled.td`

display:flex;

justify-content:center;
align-items:center;
        `;
const PTag3 = styled.p`
margin-top:-3rem;
     margin-right:5rem;
        margin-left:auto;
        font-family:Poppins,Regular;
        font-weight:600;

        @media screen and (min-width:320px) and (max-width:1080px){
          margin:auto;
          padding-bottom:1rem;
                  }
        `;


        const PTag4 = styled.p`
       
       

        margin-left:20rem;
        font-family:Poppins,Regular;
        font-weight:600;

        @media screen and (min-width:320px) and (max-width:1080px){
margin:auto;
        }
        `;

const InputContainer = styled.div`
        display:flex;
        flex-direction:row;
        margin-left:auto;
        font-family:"Poppins,Regular";
        font-weight:600;
        padding-top:1rem;
        margin-right:1.5rem;
        // border:1px solid #4E8FAE;
        box-shadow: 3px 6px 9px #00000005;
        @media screen and (min-width:320px) and (max-width:1080px){

        }
        `;
const Icon = styled.div`
        height: 3rem;
        width: 2rem;
        background-color: #F2F3F4;
        display:flex;
        flex-direction:row
        justify-content: center;
        align-items: center;
        border-top-left-radius:10px;
        border-bottom-left-radius:10px;
        svg{
          color:#555555;
}
        `;
const Input = styled.input`
        justify-content: center;
        height: 3rem;
        width: 25rem;
        border:0px solid #4E8FAE;
        background-color:#F2F3F4;
        font-family:Poppins,Medium;
        font-Weight:600;
        border-top-right-radius:10px;
        border-bottom-right-radius:10px;
        &:focus{
          border:none;
        outline:none;
}
        @media screen and (min-width:320px) and (max-width:1080px){
          width: 15rem;
}
        `;
const Numbers = styled.li`
        @media screen and (min-width:320px) and (max-width:1080px){
          display:flex;
        align-items:center;
        justify-content:center;
        width:7rem;
}
        `;

        const Row =styled.div`
background-color:white;
height:max-content;
padding-bottom:1rem;
@media screen and (min-width:320px) and (max-width:1080px){
  display:flex;
  justify-content:center;
 flex-direction:column;
 align-items:center;
width:100%;
}
`;


const Num2 = styled.li`
        @media screen and (min-width:320px) and (max-width:1080px){
          display:flex;
        margin:auto;
        flex-direction:column;
        align-items:center;
        justify-content:center;
        width:100%;
}
        `;
export default CandidateAdminDashboard